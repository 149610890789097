import type {
  CarElement,
  CoreFields,
  Kanban,
  Lang,
  PackageDeal,
  PackageDealDesc,
  PackageDealStatus,
  PurchaseOrder,
  Sequence,
  SiteConfiguration,
  SparePartManagementType,
  UIContract,
} from '@stimcar/libs-base';
import {
  arrayToMap,
  kanbanHelpers,
  nonDeleted,
  packageDealHelpers,
  workflowHelpers,
} from '@stimcar/libs-base';
import { isTruthy, nonnull } from '@stimcar/libs-kernel';
import type { PackageDealDescIssues } from '../validation/index.js';
import { toPackageDealDescIssues, validatePackageDealDescCode } from '../validation/index.js';

export type PackageDealCreationResults = {
  readonly createdPackageDeals: readonly PackageDeal[];
  readonly issues: PackageDealDescIssues;
};

export type PackageDealsWithIssues = {
  readonly packageDeals: readonly PackageDeal[];
  readonly issues: PackageDealDescIssues;
};

function computePackageDealStatus(
  { code, operationDescs }: PackageDealDesc,
  linearizedWorkflow: readonly string[],
  lastValidationStandPosition: number
): PackageDealStatus | null {
  const activeODs = operationDescs.filter(nonDeleted);
  if (packageDealHelpers.isPackageDealUnremovable(code)) {
    return 'available';
  }

  // If this is a PackageDeal without operations (and if it has not been marked as mandatory), leave its status to null.
  // The only reason to force a status for a packageDeal is if it has operations before the validation stand, because if not
  // the operations will not been available and operators will not see them until the validation will be done
  if (activeODs.length > 0) {
    const closestStandIndex = activeODs.reduce((acc, od) => {
      const operationStandPositionInWorkflow = linearizedWorkflow.findIndex(
        (s) => s === od.standId
      );
      return acc === -1 || operationStandPositionInWorkflow < acc
        ? operationStandPositionInWorkflow
        : acc;
    }, -1);

    if (closestStandIndex < lastValidationStandPosition) {
      return 'available';
    }
  }
  return null;
}

function computeNonExistingPackageDealCreation(
  pkgDealDescCodes: readonly string[],
  alreadyExistingPackageDealCodes: readonly string[],
  pkgDealDescsByCode: Map<string, PackageDealDesc>,
  carElementsById: Map<string, CarElement>,
  linearizedWorkflow: readonly string[],
  lastValidationStandPosition: number,
  sequence: Sequence,
  sparePartManagementType: SparePartManagementType,
  roundPriceTo: number,
  purchaseOrderId?: string
): readonly PackageDeal[] {
  return pkgDealDescCodes
    .filter((packageDealCode) => !alreadyExistingPackageDealCodes.includes(packageDealCode))
    .map((packageDealCode) => nonnull(pkgDealDescsByCode.get(packageDealCode)))
    .map((packageDealDesc) => {
      const carElementId = packageDealDesc.carElementIds[0];
      const carElement = carElementsById.get(carElementId);
      const packageDealStatus = computePackageDealStatus(
        packageDealDesc,
        linearizedWorkflow,
        lastValidationStandPosition
      );

      return packageDealHelpers.createPackageDealFromPackageDealDesc(
        sequence,
        packageDealDesc,
        carElement,
        sparePartManagementType,
        {
          globalVariableValues: null,
          packageDealStatus,
          roundPriceTo,
          purchaseOrderId,
        }
      );
    });
}

export function createPackageDeal(
  packageDealDesc: PackageDealDesc,
  sequence: Sequence,
  siteConfiguration: SiteConfiguration,
  workflowId: string,
  carElements: readonly CarElement[],
  sparePartManagementType: SparePartManagementType,
  roundPriceTo: number,
  purchaseOrderId?: string
): PackageDeal {
  const carElementsById = arrayToMap(carElements, ({ id }) => id);
  const workflow = siteConfiguration.workflows.find((c) => c.id === workflowId);
  const validationStands =
    workflowHelpers.getAllStandsOfTypeByWorkflowId(siteConfiguration, 'expertiseValidation')[
      workflowId
    ] ?? [];
  const linearizedWorkflow = workflowHelpers.linearize(nonnull(workflow).definition);
  const lastValidationStandPosition = linearizedWorkflow.reduce(
    (acc, s, i): number => (validationStands.includes(s) ? i : acc),
    -1
  );

  const carElementId = packageDealDesc.carElementIds[0];
  const carElement = carElementsById.get(carElementId);

  const packageDealStatus = computePackageDealStatus(
    packageDealDesc,
    linearizedWorkflow,
    lastValidationStandPosition
  );

  return packageDealHelpers.createPackageDealFromPackageDealDesc(
    sequence,
    packageDealDesc,
    carElement,
    sparePartManagementType,
    {
      globalVariableValues: null,
      packageDealStatus,
      roundPriceTo,
      purchaseOrderId,
    }
  );
}

export function createNonExistingPackageDeals(
  pkgDealCodesToCreate: readonly string[],
  existingPackageDeals: readonly Pick<PackageDeal, 'code' | 'deleted' | 'purchaseOrderId'>[],
  sequence: Sequence,
  pkgDealDescsByCode: Map<string, PackageDealDesc>,
  carElementsById: Map<string, CarElement>,
  sparePartManagementType: SparePartManagementType,
  linearizedWorkflow: readonly string[],
  lastValidationStandPosition: number,
  roundPriceTo: number,
  purchaseOrders?: readonly PurchaseOrder[]
): PackageDealCreationResults {
  const pkgDealDescValidationResults = pkgDealCodesToCreate.map((pkgDealCode) =>
    validatePackageDealDescCode(pkgDealCode, pkgDealDescsByCode)
  );
  const pkgDealDescIssues = toPackageDealDescIssues(pkgDealDescValidationResults);
  const validatedPkgDealDescCodes = pkgDealDescValidationResults
    .filter(({ isValid }) => isValid)
    .map(({ code }) => code);

  if (isTruthy(purchaseOrders)) {
    // Create package deals for each purchase order
    const createdPackageDealsForEachPurchaseOrder = purchaseOrders.flatMap((purchaseOrder) => {
      const alreadyExistingPackageDealCodes = existingPackageDeals
        .filter(nonDeleted)
        .filter(({ purchaseOrderId }) => purchaseOrderId === purchaseOrder.id)
        .map(({ code }) => code);

      return computeNonExistingPackageDealCreation(
        validatedPkgDealDescCodes,
        alreadyExistingPackageDealCodes,
        pkgDealDescsByCode,
        carElementsById,
        linearizedWorkflow,
        lastValidationStandPosition,
        sequence,
        sparePartManagementType,
        roundPriceTo,
        purchaseOrder.id
      );
    });
    return {
      createdPackageDeals: createdPackageDealsForEachPurchaseOrder,
      issues: pkgDealDescIssues,
    };
  }
  // create non allocated package deals
  const alreadyExistingPackageDealCodes = existingPackageDeals
    .filter(nonDeleted)
    .filter(({ purchaseOrderId }) => !isTruthy(purchaseOrderId))
    .map(({ code }) => code);
  const createdPackageDeals = computeNonExistingPackageDealCreation(
    validatedPkgDealDescCodes,
    alreadyExistingPackageDealCodes,
    pkgDealDescsByCode,
    carElementsById,
    linearizedWorkflow,
    lastValidationStandPosition,
    sequence,
    sparePartManagementType,
    roundPriceTo
  );

  return {
    createdPackageDeals,
    issues: pkgDealDescIssues,
  };
}

export function createDefaultPackageDealsForContractAndWorkflow(
  sequence: Sequence,
  originalKanban: CoreFields<Kanban>,
  siteConfiguration: SiteConfiguration,
  packageDealDescs: readonly PackageDealDesc[],
  carElements: readonly CarElement[],
  contract: UIContract,
  workflowId: string,
  lang: Lang
): PackageDealsWithIssues {
  const pkgDealCodesToCreateForKanban = contract.pkgDealDescCodesForKanban[workflowId];
  const pkgDealCodesToCreateForPurchaseOrders =
    contract.pkgDealDescCodesForPurchaseOrder[workflowId];

  // Keep only PDD related to the given contract
  const contractPackageDealDescs = packageDealDescs.filter(
    ({ database }) => contract.packageDealDatabase === database
  );
  const pkgDealDescsByCode = arrayToMap(contractPackageDealDescs, ({ code }) => code);
  const carElementsById = arrayToMap(carElements, ({ id }) => id);

  const validationStands =
    workflowHelpers.getAllStandsOfTypeByWorkflowId(siteConfiguration, 'expertiseValidation')[
      workflowId
    ] ?? [];
  const workflow = siteConfiguration.workflows.find((c) => c.id === workflowId);
  const linearizedWorkflow = workflowHelpers.linearize(nonnull(workflow).definition);
  const lastValidationStandPosition = linearizedWorkflow.reduce(
    (acc, s, i): number => (validationStands.includes(s) ? i : acc),
    -1
  );

  const pkgDealCreationResultForKanban = createNonExistingPackageDeals(
    pkgDealCodesToCreateForKanban,
    originalKanban.packageDeals,
    sequence,
    pkgDealDescsByCode,
    carElementsById,
    contract.sparePartManagementType,
    linearizedWorkflow,
    lastValidationStandPosition,
    originalKanban.contract.configuration.roundPriceTo
  );

  const pkgDealCreationResultForPurchaseOrders = createNonExistingPackageDeals(
    pkgDealCodesToCreateForPurchaseOrders,
    originalKanban.packageDeals,
    sequence,
    pkgDealDescsByCode,
    carElementsById,
    contract.sparePartManagementType,
    linearizedWorkflow,
    lastValidationStandPosition,
    originalKanban.contract.configuration.roundPriceTo,
    originalKanban.purchaseOrders
  );

  const updatedPackageDeals = [
    ...originalKanban.packageDeals,
    ...pkgDealCreationResultForKanban.createdPackageDeals,
    ...pkgDealCreationResultForPurchaseOrders.createdPackageDeals,
  ];

  // Configure ADMIN package deal
  const updatedPackageDealsWithAdmin = kanbanHelpers.configureAdminPackageDeal(
    updatedPackageDeals,
    sequence,
    lang
  );

  const packageDealsWithExpressionComputation =
    packageDealHelpers.updateAllPackageDealsExpressionComputations(
      {
        ...originalKanban,
        packageDeals: updatedPackageDealsWithAdmin,
      },
      true
    );

  return {
    packageDeals: packageDealsWithExpressionComputation,
    issues: {
      unknownForWorkflowPddCodes: new Set([
        ...pkgDealCreationResultForKanban.issues.unknownForWorkflowPddCodes,
        ...pkgDealCreationResultForPurchaseOrders.issues.unknownForWorkflowPddCodes,
      ]),
      multipleCarElementsPddCodes: new Set([
        ...pkgDealCreationResultForKanban.issues.multipleCarElementsPddCodes,
        ...pkgDealCreationResultForPurchaseOrders.issues.multipleCarElementsPddCodes,
      ]),
      multipleValuesVariablesWithoutDefaultPddCodes: new Set([
        ...pkgDealCreationResultForKanban.issues.multipleValuesVariablesWithoutDefaultPddCodes,
        ...pkgDealCreationResultForPurchaseOrders.issues
          .multipleValuesVariablesWithoutDefaultPddCodes,
      ]),
    },
  };
}
