import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban, PackageDeal } from '@stimcar/libs-base';
import type {
  MarketplaceBuyOverview,
  MarketplaceOverviewRequest,
  MarketplacePackageDeal,
  MarketplaceSaleOverview,
} from '@stimcar/libs-kernel';
import type { GlobalStoreStateSelector, StoreStateSelector } from '@stimcar/libs-uikernel';
import {
  CoreBackendRoutes,
  KANBAN_ATTRIBUTES,
  kanbanHelpers,
  MARKETPLACE_BUY_PURCHASE_ORDER,
  MARKETPLACE_SALE_PURCHASE_ORDER,
  MKTP_PKG_CATEGORY_BUY_FIXED_FEES,
  MKTP_PKG_CATEGORY_REGISTRATION_FEES,
  MKTP_PKG_CATEGORY_SALE_FIXED_FEES,
  MKTP_PKG_DEAL_CODE_VARIABLE_FEES,
  MKTP_PKG_DEAL_VARIABLE_FEES_MAX_PRICE_VARIABLE,
  MKTP_PKG_DEAL_VARIABLE_FEES_MIN_PRICE_VARIABLE,
  MKTP_PKG_DEAL_VARIABLE_FEES_RATE_VARIABLE,
  nonDeleted,
  packageDealHelpers,
} from '@stimcar/libs-base';
import {
  applyPayload,
  computePayload,
  isTruthy,
  MKTP_BUY_PROCESS,
  MKTP_SELL_PROCESS,
} from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Button, Input, ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type { MarketplaceOverviewState } from './typings/store.js';

type CashSentinelValidation = {
  readonly sellerPriceWithVAT: number;
  readonly buyTotalFeesWithVAT: number;
  readonly saleTotalFeesWithVAT: number;
  readonly deposit: number;
  readonly totalCashSentinelWithVAT: number;
};

export function formatPriceWithSpacesAndTwoDecimals(number: number): string {
  const price = new Intl.NumberFormat('fr-FR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
  return `${price} €`;
}

function availableAndNonDeleted(pkgDeal: PackageDeal): boolean {
  return pkgDeal.status === 'available' && nonDeleted(pkgDeal);
}

function computePriceWithoutVAT(priceWithVAT: number, VATRate: number): number {
  return priceWithVAT / (1 + VATRate);
}

function getBuyRefurbishFeesPackageDeals(kanban: Kanban): readonly PackageDeal[] {
  return kanbanHelpers
    .getPackageDealsAllocatedToPurchaseNumber(kanban, MARKETPLACE_BUY_PURCHASE_ORDER)
    .filter(({ marketplaceBuyCategory }) => !isTruthy(marketplaceBuyCategory))
    .filter(availableAndNonDeleted);
}

function getBuyRegistrationFeesPackageDeals(kanban: Kanban): readonly PackageDeal[] {
  return kanbanHelpers
    .getPackageDealsAllocatedToPurchaseNumber(kanban, MARKETPLACE_BUY_PURCHASE_ORDER)
    .filter(
      ({ marketplaceBuyCategory }) => marketplaceBuyCategory === MKTP_PKG_CATEGORY_REGISTRATION_FEES
    )
    .filter(availableAndNonDeleted);
}

function getBuyFixedFeesPackageDeals(kanban: Kanban): readonly PackageDeal[] {
  return kanbanHelpers
    .getPackageDealsAllocatedToPurchaseNumber(kanban, MARKETPLACE_BUY_PURCHASE_ORDER)
    .filter(
      ({ marketplaceBuyCategory }) => marketplaceBuyCategory === MKTP_PKG_CATEGORY_BUY_FIXED_FEES
    )
    .filter(availableAndNonDeleted);
}

function getSaleRefurbishFeesPackageDeals(kanban: Kanban): readonly PackageDeal[] {
  return kanbanHelpers
    .getPackageDealsAllocatedToPurchaseNumber(kanban, MARKETPLACE_SALE_PURCHASE_ORDER)
    .filter(({ marketplaceSaleCategory }) => !isTruthy(marketplaceSaleCategory))
    .filter(availableAndNonDeleted);
}

function getSaleFixedFeesPackageDeals(kanban: Kanban): readonly PackageDeal[] {
  return kanbanHelpers
    .getPackageDealsAllocatedToPurchaseNumber(kanban, MARKETPLACE_SALE_PURCHASE_ORDER)
    .filter(
      ({ marketplaceSaleCategory }) => marketplaceSaleCategory === MKTP_PKG_CATEGORY_SALE_FIXED_FEES
    )
    .filter(availableAndNonDeleted);
}

function computeCashSentinelValidation(
  sellerPriceWithVAT: number,
  buyTotalFeesWithVAT: number,
  saleTotalFeesWithVAT: number,
  deposit: number
): CashSentinelValidation {
  const totalCashSentinelWithVAT =
    sellerPriceWithVAT + buyTotalFeesWithVAT + saleTotalFeesWithVAT - deposit;
  return {
    sellerPriceWithVAT,
    buyTotalFeesWithVAT,
    saleTotalFeesWithVAT,
    deposit,
    totalCashSentinelWithVAT,
  };
}

function computeBuyVariableFeesWithVAT(kanban: Kanban, marketplacePriceWithVAT: number): number {
  const variableFeesPkgDeal = kanban.packageDeals
    .filter(nonDeleted)
    .find(({ code }) => code === MKTP_PKG_DEAL_CODE_VARIABLE_FEES);
  if (!isTruthy(variableFeesPkgDeal)) {
    return 0;
  }
  const minPriceVariable =
    variableFeesPkgDeal.variables[MKTP_PKG_DEAL_VARIABLE_FEES_MIN_PRICE_VARIABLE];
  const maxPriceVariable =
    variableFeesPkgDeal.variables[MKTP_PKG_DEAL_VARIABLE_FEES_MAX_PRICE_VARIABLE];
  const rateVariable = variableFeesPkgDeal.variables[MKTP_PKG_DEAL_VARIABLE_FEES_RATE_VARIABLE];

  if (!isTruthy(minPriceVariable) || !isTruthy(maxPriceVariable) || !isTruthy(rateVariable)) {
    return 0;
  }
  const minPrice = minPriceVariable.value as number;
  const maxPrice = maxPriceVariable.value as number;
  const rate = rateVariable.value as number;

  const variableFeesWithVAT = marketplacePriceWithVAT * rate;
  if (variableFeesWithVAT < minPrice) {
    return minPrice;
  }
  if (variableFeesWithVAT > maxPrice) {
    return maxPrice;
  }
  return variableFeesWithVAT;
}

function computeBuyerPriceWithVAT(
  marketplacePrice: number,
  buyRefurbishFeesTotalWithVAT: number,
  buyRegistrationFeesTotalWithVAT: number
): number {
  return marketplacePrice + buyRefurbishFeesTotalWithVAT + buyRegistrationFeesTotalWithVAT;
}

function computeSellerPriceWithVAT(
  salePriceWithVAT: number,
  saleRefurbishFeesTotalWithVAT: number,
  saleFixedFeesTotalWithVAT: number
): number {
  return salePriceWithVAT - saleRefurbishFeesTotalWithVAT - saleFixedFeesTotalWithVAT;
}

function computeMarketplacePackageDeal(pkgDeal: PackageDeal): MarketplacePackageDeal {
  return {
    label: packageDealHelpers.getPackageDealDisplayedLabel(pkgDeal),
    VATRate: pkgDeal.ignoreVAT ? 0 : 20,
    priceWithVAT: packageDealHelpers.getPackageDealsPriceWithVAT([pkgDeal]),
  };
}

function computeMarketplaceSaleOverview(
  salePriceWithVAT: number,
  fixedFeesPackageDeals: readonly PackageDeal[],
  refurbishFeesPackageDeals: readonly PackageDeal[]
): MarketplaceSaleOverview {
  return {
    salePriceWithVAT,
    fixedFeesPackageDeals: fixedFeesPackageDeals.map((pkgDeal) =>
      computeMarketplacePackageDeal(pkgDeal)
    ),
    refurbishFeesPackageDeals: refurbishFeesPackageDeals.map((pkgDeal) =>
      computeMarketplacePackageDeal(pkgDeal)
    ),
  };
}

function computeMarketplaceBuyOverview(
  salePriceWithVAT: number,
  deposit: number,
  registrationFeesPackageDeals: readonly PackageDeal[],
  fixedFeesPackageDeals: readonly PackageDeal[],
  refurbishFeesPackageDeals: readonly PackageDeal[]
): MarketplaceBuyOverview {
  return {
    salePriceWithVAT,
    deposit,
    registrationFeesPackageDeals: registrationFeesPackageDeals.map((pkgDeal) =>
      computeMarketplacePackageDeal(pkgDeal)
    ),
    fixedFeesPackageDeals: fixedFeesPackageDeals.map((pkgDeal) =>
      computeMarketplacePackageDeal(pkgDeal)
    ),
    refurbishFeesPackageDeals: refurbishFeesPackageDeals.map((pkgDeal) =>
      computeMarketplacePackageDeal(pkgDeal)
    ),
  };
}

interface LabelWithPriceProps {
  readonly label: string;
  readonly price: number;
  readonly isPrimary?: boolean;
}

function LabelWithPrice({ label, price, isPrimary = false }: LabelWithPriceProps): JSX.Element {
  const size = isPrimary ? 'is-size-6 has-text-weight-bold' : 'is-size-6';
  return (
    <div className={`${size} is-flex is-justify-content-space-between`}>
      <div>{label}</div>
      <div>{formatPriceWithSpacesAndTwoDecimals(price)}</div>
    </div>
  );
}

export interface MarketplaceOverviewProps {
  readonly $: StoreStateSelector<Store, MarketplaceOverviewState>;
  readonly $gs: GlobalStoreStateSelector<Store>;
  readonly $selectedKanban: StoreStateSelector<Store, Kanban>;
}

export function MarketplaceOverview({
  $,
  $gs,
  $selectedKanban,
}: MarketplaceOverviewProps): JSX.Element {
  const [t] = useTranslation('details');
  const isOnline = useGetState($gs.$session.$isOnline);
  const kanbanId = useGetState($selectedKanban.$id);
  const kanban = useGetState($selectedKanban);
  const marketplacePriceAsText = useGetState($.$marketplacePrice);
  const depositAsText = useGetState($.$deposit);

  const marketplacePrice = useMemo(
    () => Number.parseFloat(marketplacePriceAsText),
    [marketplacePriceAsText]
  );
  const deposit = useMemo(() => Number.parseFloat(depositAsText), [depositAsText]);

  const buyRegistrationFeesPkgDeals = useMemo(
    () => getBuyRegistrationFeesPackageDeals(kanban),
    [kanban]
  );
  const buyRefurbishFeesPkgDeals = useMemo(() => getBuyRefurbishFeesPackageDeals(kanban), [kanban]);
  const buyFixedFeesPkgDeals = useMemo(() => getBuyFixedFeesPackageDeals(kanban), [kanban]);
  const saleRefurbishFeesPkgDeals = useMemo(
    () => getSaleRefurbishFeesPackageDeals(kanban),
    [kanban]
  );
  const saleFixedFeesPkgDeals = useMemo(() => getSaleFixedFeesPackageDeals(kanban), [kanban]);

  const buyRegistrationFeesTotalWithVAT = useMemo(
    () => packageDealHelpers.getPackageDealsPriceWithVAT(buyRegistrationFeesPkgDeals),
    [buyRegistrationFeesPkgDeals]
  );
  const buyRefurbishFeesTotalWithVAT = useMemo(
    () => packageDealHelpers.getPackageDealsPriceWithVAT(buyRefurbishFeesPkgDeals),
    [buyRefurbishFeesPkgDeals]
  );
  const buyFixedFeesTotalWithVAT = useMemo(
    () => packageDealHelpers.getPackageDealsPriceWithVAT(buyFixedFeesPkgDeals),
    [buyFixedFeesPkgDeals]
  );
  const buyTotalFeesWithVAT = useMemo(
    () => buyRegistrationFeesTotalWithVAT + buyRefurbishFeesTotalWithVAT + buyFixedFeesTotalWithVAT,
    [buyRegistrationFeesTotalWithVAT, buyRefurbishFeesTotalWithVAT, buyFixedFeesTotalWithVAT]
  );

  const saleRefurbishFeesTotalWithVAT = useMemo(
    () => packageDealHelpers.getPackageDealsPriceWithVAT(saleRefurbishFeesPkgDeals),
    [saleRefurbishFeesPkgDeals]
  );
  const saleFixedFeesTotalWithVAT = useMemo(
    () => packageDealHelpers.getPackageDealsPriceWithVAT(saleFixedFeesPkgDeals),
    [saleFixedFeesPkgDeals]
  );
  const saleTotalFeesWithVAT = useMemo(
    () => saleRefurbishFeesTotalWithVAT + saleFixedFeesTotalWithVAT,
    [saleRefurbishFeesTotalWithVAT, saleFixedFeesTotalWithVAT]
  );

  const salePriceWithVAT = useMemo(
    () => marketplacePrice - buyFixedFeesTotalWithVAT,
    [marketplacePrice, buyFixedFeesTotalWithVAT]
  );
  const buyerPriceWithVAT = useMemo(
    () =>
      computeBuyerPriceWithVAT(
        marketplacePrice,
        buyRefurbishFeesTotalWithVAT,
        buyRegistrationFeesTotalWithVAT
      ),
    [marketplacePrice, buyRefurbishFeesTotalWithVAT, buyRegistrationFeesTotalWithVAT]
  );
  const sellerPriceWithVAT = useMemo(
    () =>
      computeSellerPriceWithVAT(
        salePriceWithVAT,
        saleRefurbishFeesTotalWithVAT,
        saleFixedFeesTotalWithVAT
      ),
    [salePriceWithVAT, saleRefurbishFeesTotalWithVAT, saleFixedFeesTotalWithVAT]
  );

  const cashSentinelValidation = useMemo(
    () =>
      computeCashSentinelValidation(
        sellerPriceWithVAT,
        buyTotalFeesWithVAT,
        saleTotalFeesWithVAT,
        deposit
      ),
    [sellerPriceWithVAT, buyTotalFeesWithVAT, saleTotalFeesWithVAT, deposit]
  );

  const buyVariableFeesWithVATAccordingToCurrentMarketplacePrice = useMemo(
    () => computeBuyVariableFeesWithVAT(kanban, marketplacePrice),
    [kanban, marketplacePrice]
  );

  const saveOverviewValuesToKanban = useActionCallback(
    async ({ actionDispatch, kanbanRepository, getState }): Promise<void> => {
      const { marketplacePrice, deposit } = getState();
      const updatedKanban = applyPayload(kanban, {
        attributes: {
          [KANBAN_ATTRIBUTES.MKTP_PUBLIC_SELLING_PRICE]: marketplacePrice,
          [KANBAN_ATTRIBUTES.MKTP_DEPOSIT]: deposit,
        },
      });
      // Update the kanban in the repository if needed
      const payload = computePayload(kanban, updatedKanban);
      if (payload !== undefined) {
        await kanbanRepository.updateEntityFromPayload({ entityId: kanbanId, payload });
      }
      actionDispatch.scopeProperty('saveConfirmationDialog').setProperty('active', false);
    },
    [kanbanId, kanban],
    $
  );

  const applyVariableFeesValueToKanban = useActionCallback(
    async ({ actionDispatch, kanbanRepository }): Promise<void> => {
      await actionDispatch.execCallback(saveOverviewValuesToKanban);

      const variableFeesPkgDeal = kanban.packageDeals
        .filter(nonDeleted)
        .find(({ code }) => code === MKTP_PKG_DEAL_CODE_VARIABLE_FEES);
      if (!isTruthy(variableFeesPkgDeal)) {
        throw Error(t('tabs.marketplace.sellProcess.missingVariableFeesPackageDealError'));
      }

      const updatedKanban = applyPayload(kanban, {
        packageDeals: [
          {
            id: variableFeesPkgDeal.id,
            // Set price without VAT
            price: computePriceWithoutVAT(
              buyVariableFeesWithVATAccordingToCurrentMarketplacePrice,
              0.2
            ),
          },
        ],
      });
      // Update the kanban in the repository if needed
      const payload = computePayload(kanban, updatedKanban);
      if (payload !== undefined) {
        await kanbanRepository.updateEntityFromPayload({ entityId: kanbanId, payload });
      }
      actionDispatch.scopeProperty('variableFeesConfirmationDialog').setProperty('active', false);
    },
    [
      t,
      kanbanId,
      kanban,
      buyVariableFeesWithVATAccordingToCurrentMarketplacePrice,
      saveOverviewValuesToKanban,
    ],
    $
  );

  const marketplaceBuyOverviewActionCallback = useActionCallback(
    async ({ actionDispatch, httpClient }): Promise<void> => {
      await actionDispatch.execCallback(saveOverviewValuesToKanban);
      const buyOverview = computeMarketplaceBuyOverview(
        salePriceWithVAT,
        deposit,
        buyRegistrationFeesPkgDeals,
        buyFixedFeesPkgDeals,
        buyRefurbishFeesPkgDeals
      );
      await httpClient.httpPostAsJSON<MarketplaceOverviewRequest, null>(
        CoreBackendRoutes.CREATE_MARKETPLACE_OVERVIEW_DOC(kanbanId, MKTP_BUY_PROCESS),
        { buyOverview },
        'POST'
      );
      actionDispatch.scopeProperty('overviewButtonDialog').setProperty('active', true);
    },
    [
      kanbanId,
      salePriceWithVAT,
      deposit,
      buyRegistrationFeesPkgDeals,
      buyFixedFeesPkgDeals,
      buyRefurbishFeesPkgDeals,
      saveOverviewValuesToKanban,
    ],
    $
  );

  const marketplaceSaleOverviewActionCallback = useActionCallback(
    async ({ actionDispatch, httpClient }): Promise<void> => {
      await actionDispatch.execCallback(saveOverviewValuesToKanban);
      const saleOverview = computeMarketplaceSaleOverview(
        salePriceWithVAT,
        saleFixedFeesPkgDeals,
        saleRefurbishFeesPkgDeals
      );
      await httpClient.httpPostAsJSON<MarketplaceOverviewRequest, null>(
        CoreBackendRoutes.CREATE_MARKETPLACE_OVERVIEW_DOC(kanbanId, MKTP_SELL_PROCESS),
        { saleOverview },
        'POST'
      );
      actionDispatch.scopeProperty('overviewButtonDialog').setProperty('active', true);
    },
    [
      kanbanId,
      salePriceWithVAT,
      saleFixedFeesPkgDeals,
      saleRefurbishFeesPkgDeals,
      saveOverviewValuesToKanban,
    ],
    $
  );

  const openSaveConfirmationModalActionCallback = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.scopeProperty('saveConfirmationDialog').setProperty('active', true);
    },
    [],
    $
  );

  const openVariableFeesConfirmationModalActionCallback = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.scopeProperty('variableFeesConfirmationDialog').setProperty('active', true);
    },
    [],
    $
  );

  const closeOverviewGenerationModalActionCallback = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.scopeProperty('overviewButtonDialog').setProperty('active', false);
    },
    [],
    $
  );

  const closeVariableFeesConfirmationModalActionCallback = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.scopeProperty('variableFeesConfirmationDialog').setProperty('active', false);
    },
    [],
    $
  );

  const loadValuesFromKanbanAsyncEffect = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.applyPayload({
        marketplacePrice: String(
          kanban?.attributes[KANBAN_ATTRIBUTES.MKTP_PUBLIC_SELLING_PRICE] ?? 0
        ),
        deposit: String(kanban?.attributes[KANBAN_ATTRIBUTES.MKTP_DEPOSIT] ?? 0),
      });
    },
    [kanban],
    $
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadValuesFromKanbanAsyncEffect();
  }, [kanban, loadValuesFromKanbanAsyncEffect]);

  return (
    <>
      <ModalCardDialog
        $active={$.$overviewButtonDialog.$active}
        title={t('tabs.marketplace.overview.modal.generationModalTitle')}
        onOkClicked={closeOverviewGenerationModalActionCallback}
        dontShowCancelButton
      >
        <p>{t('tabs.marketplace.overview.modal.generationModalMessage')}</p>
      </ModalCardDialog>
      <ModalCardDialog
        $active={$.$saveConfirmationDialog.$active}
        title={t('tabs.marketplace.overview.modal.saveConfirmationModalTitle')}
        onOkClicked={saveOverviewValuesToKanban}
        dontShowCancelButton
      >
        <p>{t('tabs.marketplace.overview.modal.saveConfirmationModalMessage')}</p>
      </ModalCardDialog>
      <ModalCardDialog
        $active={$.$variableFeesConfirmationDialog.$active}
        title={t('tabs.marketplace.overview.modal.variableFeesConfirmationModalTitle')}
        onOkClicked={applyVariableFeesValueToKanban}
        onCancelClicked={closeVariableFeesConfirmationModalActionCallback}
      >
        <p>
          {t('tabs.marketplace.overview.modal.variableFeesConfirmationModalMessage', {
            buyVariableFeesWithVAT: formatPriceWithSpacesAndTwoDecimals(
              buyVariableFeesWithVATAccordingToCurrentMarketplacePrice
            ),
          })}
        </p>
      </ModalCardDialog>
      <div className="columns m-2">
        <div className="column is-2 is-flex is-align-items-center has-text-weight-bold">
          {t('tabs.marketplace.overview.marketplacePrice')}
        </div>
        <div className="column is-2 is-flex is-align-items-center">
          <Input $={$.$marketplacePrice} type="number" className="has-text-right" />
        </div>
        <div className="column is-8">
          <Button
            iconId="save"
            label={t('tabs.marketplace.saveButton')}
            onClick={openSaveConfirmationModalActionCallback}
            additionalClass="is-primary mx-2 my-2"
            disabled={!isOnline}
          />
          <Button
            iconId="dollar-sign"
            label={t('tabs.marketplace.variableFeesButton')}
            onClick={openVariableFeesConfirmationModalActionCallback}
            additionalClass="is-primary mx-2 my-2"
            disabled={!isOnline}
          />
          <Button
            iconId="file-invoice-dollar"
            label={t('tabs.marketplace.buyOverviewButton')}
            onClick={marketplaceBuyOverviewActionCallback}
            additionalClass="is-primary mx-2 my-2"
            disabled={!isOnline}
          />
          <Button
            iconId="file-invoice-dollar"
            label={t('tabs.marketplace.saleOverviewButton')}
            onClick={marketplaceSaleOverviewActionCallback}
            additionalClass="is-primary mx-2 my-2"
            disabled={!isOnline}
          />
        </div>
      </div>
      {}
      <table className="table is-striped is-bordered is-hoverable is-fullwidth mx-2" role="none">
        <tbody>
          <tr key="buyRefurbishFeesRow">
            <td className="is-size-6 has-text-weight-bold">
              {t('tabs.marketplace.overview.buyRefurbishFees.title')}
            </td>
            <td className="has-text-centered">
              {formatPriceWithSpacesAndTwoDecimals(buyerPriceWithVAT)}
            </td>
            <td>
              <details>
                <summary className="has-text-weight-bold">
                  {`${t('tabs.marketplace.overview.buyRefurbishFees.description')} - ${formatPriceWithSpacesAndTwoDecimals(buyRegistrationFeesTotalWithVAT + buyRefurbishFeesTotalWithVAT)}`}
                </summary>
                {[...buyRegistrationFeesPkgDeals, ...buyRefurbishFeesPkgDeals].map((pkgDeal) => (
                  <LabelWithPrice
                    key={pkgDeal.id}
                    label={packageDealHelpers.getPackageDealDisplayedLabel(pkgDeal)}
                    price={packageDealHelpers.getPackageDealsPriceWithVAT([pkgDeal])}
                  />
                ))}
              </details>
            </td>
          </tr>
          <tr key="buyFixedFeesRow">
            <td className="is-size-6 has-text-weight-bold">
              {t('tabs.marketplace.overview.buyFixedFees.title')}
            </td>
            <td className="has-text-centered">
              {formatPriceWithSpacesAndTwoDecimals(marketplacePrice)}
            </td>
            <td>
              <details>
                <summary className="has-text-weight-bold">
                  {`${t('tabs.marketplace.overview.buyFixedFees.description')} - ${formatPriceWithSpacesAndTwoDecimals(buyFixedFeesTotalWithVAT)}`}
                </summary>
                {buyFixedFeesPkgDeals.map((pkgDeal) => (
                  <LabelWithPrice
                    key={pkgDeal.id}
                    label={packageDealHelpers.getPackageDealDisplayedLabel(pkgDeal)}
                    price={packageDealHelpers.getPackageDealsPriceWithVAT([pkgDeal])}
                  />
                ))}
              </details>
            </td>
          </tr>
          <tr key="saleRow">
            <td className="is-size-6 has-text-weight-bold">
              {t('tabs.marketplace.overview.saleFees.title')}
            </td>
            <td className="has-text-centered">
              {formatPriceWithSpacesAndTwoDecimals(salePriceWithVAT)}
            </td>
            <td>
              <details>
                <summary className="has-text-weight-bold">
                  {`${t('tabs.marketplace.overview.saleFees.description')} - ${formatPriceWithSpacesAndTwoDecimals(saleTotalFeesWithVAT)}`}
                </summary>
                {[...saleRefurbishFeesPkgDeals, ...saleFixedFeesPkgDeals].map((pkgDeal) => (
                  <LabelWithPrice
                    key={pkgDeal.id}
                    label={packageDealHelpers.getPackageDealDisplayedLabel(pkgDeal)}
                    price={packageDealHelpers.getPackageDealsPriceWithVAT([pkgDeal])}
                  />
                ))}
              </details>
            </td>
          </tr>
          <tr key="saleTotalRow">
            <td className="is-size-6 has-text-weight-bold">
              {t('tabs.marketplace.overview.saleTotal.title')}
            </td>
            <td className="has-text-centered">
              {formatPriceWithSpacesAndTwoDecimals(sellerPriceWithVAT)}
            </td>
            <td />
          </tr>
        </tbody>
      </table>
      <table className="table is-striped is-bordered is-hoverable is-fullwidth mx-2">
        <thead>
          <tr>
            <th colSpan={2} className="has-text-centered">
              {t('tabs.marketplace.overview.validation.title')}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr key="saleTotalRow">
            <td>{t('tabs.marketplace.overview.validation.sellerPrice')}</td>
            <td className="has-text-right">
              {formatPriceWithSpacesAndTwoDecimals(cashSentinelValidation.sellerPriceWithVAT)}
            </td>
          </tr>
          <tr key="buyFeesRow">
            <td>{t('tabs.marketplace.overview.validation.buyFees')}</td>
            <td className="has-text-right">
              {formatPriceWithSpacesAndTwoDecimals(cashSentinelValidation.buyTotalFeesWithVAT)}
            </td>
          </tr>
          <tr key="saleFeesRow">
            <td>{t('tabs.marketplace.overview.validation.saleFees')}</td>
            <td className="has-text-right">
              {formatPriceWithSpacesAndTwoDecimals(cashSentinelValidation.saleTotalFeesWithVAT)}
            </td>
          </tr>
          <tr key="depositRow">
            <td>{t('tabs.marketplace.overview.validation.deposit')}</td>
            <td className="has-text-right">
              <Input $={$.$deposit} type="number" className="has-text-right" />
            </td>
          </tr>
          <tr key="totalCSRow" className="is-size-6 has-text-weight-bold">
            <td>{t('tabs.marketplace.overview.validation.totalCashSentinel')}</td>
            <td className="has-text-right">
              {formatPriceWithSpacesAndTwoDecimals(cashSentinelValidation.totalCashSentinelWithVAT)}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
