import React from 'react';
import { useTranslation } from 'react-i18next';
import type { PackageDeal } from '@stimcar/libs-base';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import { packageDealHelpers } from '@stimcar/libs-base';
import { nonnull } from '@stimcar/libs-kernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import { FaIcon } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../state/typings/store.js';
import type { AddPackageDealToDefectDialogState } from '../../../typings/store.js';
import { openAddPackageDealToDefectModalAction } from './AddPackageDealToDefectModal.js';

interface PackageDealForDefectItemProps {
  readonly $: StoreStateSelector<Store, AddPackageDealToDefectDialogState>;
  readonly packageDeal: PackageDeal;
}

export function PackageDealForDefectItem({
  $,
  packageDeal,
}: PackageDealForDefectItemProps): JSX.Element {
  const [t] = useTranslation('operators', { keyPrefix: 'defects.addOrUpdateDefect' });

  const { id, carElement } = packageDeal;

  const packageDealLabel = packageDealHelpers.getPackageDealDisplayedLabel(packageDeal);

  const openAddPackageDealToDefectModalActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openAddPackageDealToDefectModalAction, id, packageDealLabel);
    },
    [id, packageDealLabel],
    $
  );

  return (
    <tr>
      <td>{nonnull(carElement).label}</td>
      <td>{packageDealLabel}</td>
      <td className="has-text-right">
        {packageDealHelpers.getPackageDealsPriceWithVAT([packageDeal]).toFixed(2)}
      </td>
      <td>
        <button
          key={`add-${id}`}
          aria-label="add"
          type="button"
          className="button is-small is-transparent"
          title={t('availablePackageDeals.add')}
          onClick={openAddPackageDealToDefectModalActionCallback}
        >
          <FaIcon id="add" tooltip={t('availablePackageDeals.add')} />
        </button>
      </td>
    </tr>
  );
}
