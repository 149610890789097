import type { TFunction } from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban, UIContract } from '@stimcar/libs-base';
import {
  kanbanHelpers,
  MARKETPLACE_BUY_PURCHASE_ORDER,
  MKTP_MANDATE_BUY,
  MKTP_MANDATE_NA,
  MKTP_MANDATE_SALE,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import {
  ClickableIcon,
  CustomerInformations,
  KanbanIdentityPictureComponent,
} from '@stimcar/libs-uitoolkit';
import { MarketplaceIcon } from '../../../app/utils/MarketplaceIcon.js';
import type {
  DueDateEditionToolkit,
  IdentityPictureToolkit,
  MileageEditionToolkit,
  RefitEndDateEditionToolkit,
} from './typings/toolkits.js';
import { KanbanGeneralInformations } from './KanbanGeneralInformations.js';

function getMarketplaceMandateLabel(kanban: Kanban, t: TFunction): string | undefined {
  const marketplaceMandate = kanbanHelpers.getMarketplaceMandate(kanban);
  switch (marketplaceMandate) {
    case MKTP_MANDATE_BUY:
      return t('kanbanGeneralInformations.marketplace.buyMandate');
    case MKTP_MANDATE_SALE:
      return t('kanbanGeneralInformations.marketplace.saleMandate');
    case MKTP_MANDATE_NA:
    default:
      return t('kanbanGeneralInformations.marketplace.unknown');
  }
}

interface MarketplaceCustomersInfosProps {
  readonly kanban: Kanban;
  readonly onEditCustomerButtonClicked?: () => Promise<void>;
  readonly onSelectPredefinedCustomerButtonClicked?: () => Promise<void>;
}

export function MarketplaceCustomersInfos({
  kanban,
  onEditCustomerButtonClicked,
  onSelectPredefinedCustomerButtonClicked,
}: MarketplaceCustomersInfosProps): JSX.Element {
  const [t] = useTranslation('custom');
  const marketplaceMandate = useMemo(() => getMarketplaceMandateLabel(kanban, t), [kanban, t]);
  const marketplaceBuyerCustomer = useMemo(
    () =>
      kanban.purchaseOrders.find(
        ({ purchaseNumber }) => purchaseNumber === MARKETPLACE_BUY_PURCHASE_ORDER
      )?.customer,
    [kanban]
  );
  return (
    <>
      <div>
        <b>{`${t('kanbanGeneralInformations.marketplace.mandateLabel')}`}</b>
        {marketplaceMandate}
      </div>
      <div>
        <b>{`${t('kanbanGeneralInformations.marketplace.seller')}`}</b>
        <CustomerInformations
          customer={kanban.customer}
          onEditCustomerButtonClicked={onEditCustomerButtonClicked}
          onSelectPredefinedCustomerButtonClicked={onSelectPredefinedCustomerButtonClicked}
          collapsable
        />
      </div>
      <div>
        <b>{t('kanbanGeneralInformations.marketplace.buyer')}</b>
        {isTruthy(marketplaceBuyerCustomer) ? (
          <CustomerInformations customer={marketplaceBuyerCustomer} collapsable />
        ) : (
          <div className="ml-4">{t('kanbanGeneralInformations.marketplace.unknown')}</div>
        )}
      </div>
    </>
  );
}

interface KanbanIdentityPictureAndGeneralInformationsComponentProps {
  readonly kanban: Kanban;
  readonly contract: UIContract | undefined;
  readonly displayShowInEstimateAttributes?: boolean;
  readonly onEditCustomerButtonClicked?: () => Promise<void>;
  readonly onEditGeneralInformationsButtonClicked?: () => Promise<void>;
  readonly onSelectPredefinedCustomerButtonClicked?: () => Promise<void>;
  readonly dueDateEditionToolkit?: DueDateEditionToolkit;
  readonly refitEndDateEditionToolkit?: RefitEndDateEditionToolkit;
  readonly mileageEditionToolkit?: MileageEditionToolkit;
  readonly identityPictureToolkit?: IdentityPictureToolkit;
  readonly hideTitle?: boolean;
}

export function KanbanIdentityPictureAndGeneralInformationsComponent({
  kanban,
  displayShowInEstimateAttributes = false,
  onEditCustomerButtonClicked,
  onSelectPredefinedCustomerButtonClicked,
  onEditGeneralInformationsButtonClicked,
  dueDateEditionToolkit,
  refitEndDateEditionToolkit,
  mileageEditionToolkit,
  identityPictureToolkit,
  contract,
  hideTitle = false,
}: KanbanIdentityPictureAndGeneralInformationsComponentProps): JSX.Element {
  const [t] = useTranslation('custom');
  const kanbanColor = isTruthyAndNotEmpty(kanban.infos.color)
    ? kanban.infos.color
    : t('kanbanGeneralInformations.unknownColor');

  const kanbanTitle = kanbanHelpers.getKanbanTitle(kanban.infos, kanban.attributes, t, true);
  const isMarketplace = useMemo(() => kanbanHelpers.isMarketplaceKanban(kanban), [kanban]);

  return (
    <>
      <div className="columns is-vcentered">
        {isTruthy(identityPictureToolkit) && (
          <div className="column is-narrow">
            <KanbanIdentityPictureComponent
              kanbanId={kanban.id}
              computeAttachmentUrl={identityPictureToolkit.computeUrlCallback}
              $imageModal={identityPictureToolkit.$imageModal}
              pictureEditionToolkit={identityPictureToolkit.pictureEditionToolkit}
              isOnline={identityPictureToolkit.isOnline}
            />
          </div>
        )}
        {!hideTitle && (
          <div className="column">
            <span className="title is-size-5">
              <MarketplaceIcon kanban={kanban} />
              {kanbanTitle}
            </span>
            <span className="has-text-weight-light is-uppercase">{` (${kanbanColor})`}</span>
            {onEditGeneralInformationsButtonClicked && (
              <ClickableIcon
                clickHandler={onEditGeneralInformationsButtonClicked}
                id="edit"
                elementClassName="m-l-sm"
              />
            )}
          </div>
        )}
      </div>
      <div className="columns">
        <KanbanGeneralInformations
          kanban={kanban}
          displayShowInEstimateAttributes={displayShowInEstimateAttributes}
          dueDateEditionToolkit={dueDateEditionToolkit}
          refitEndDateEditionToolkit={refitEndDateEditionToolkit}
          mileageEditionToolkit={mileageEditionToolkit}
          contract={contract}
        />
        <div className="column is-3">
          {isMarketplace ? (
            <MarketplaceCustomersInfos
              kanban={kanban}
              onEditCustomerButtonClicked={onEditCustomerButtonClicked}
              onSelectPredefinedCustomerButtonClicked={onSelectPredefinedCustomerButtonClicked}
            />
          ) : (
            <CustomerInformations
              customer={kanban.customer}
              onEditCustomerButtonClicked={onEditCustomerButtonClicked}
              onSelectPredefinedCustomerButtonClicked={onSelectPredefinedCustomerButtonClicked}
            />
          )}
        </div>
      </div>
    </>
  );
}
