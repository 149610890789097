import React from 'react';
import type { Kanban, PackageDeal } from '@stimcar/libs-base';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type { DefectsTabState } from '../../typings/store.js';
import { DefectsOnCarDisplay } from './DefectsOnCarDisplay.js';
import './DefectsTabComponent.scss';
import { DefectsTableComponent } from './DefectsTableComponent.js';
import { AddOrUpdateDefectModal } from './modal/AddOrUpdateDefectModal.js';
import { DeleteDefectModal } from './modal/DeleteDefectModal.js';

interface DefectsTabComponentProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, DefectsTabState>;
  readonly $kanban: StoreStateSelector<Store, Kanban>;
  readonly $packageDeals: StoreStateSelector<Store, readonly PackageDeal[]>;
  readonly isEditable: boolean;
}

export function DefectsTabComponent({
  $,
  $kanban,
  $packageDeals,
  $gs,
  isEditable,
}: DefectsTabComponentProps): JSX.Element {
  return (
    <>
      <div className="defects-tab-component-left-panel">
        <DefectsOnCarDisplay
          $selectedPictureId={$.$selectedPictureId}
          $kanbanId={$kanban.$id}
          $defects={$.$defects}
          $gs={$gs}
        />
      </div>
      <div className="defects-tab-component-right-panel">
        <DefectsTableComponent $={$} isEditable={isEditable} />
      </div>
      <AddOrUpdateDefectModal
        $={$.$addOrUpdateDefectDialogState}
        $kanbanId={$kanban.$id}
        $kanbanStatus={$kanban.$status}
        $gs={$gs}
        $defects={$.$defects}
        $packageDeals={$packageDeals}
      />
      <DeleteDefectModal $={$} />
    </>
  );
}
