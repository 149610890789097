import React from 'react';
import { useTranslation } from 'react-i18next';
import type { DeepPartial, RefurbishingOption } from '@stimcar/libs-kernel';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import { nonDeleted } from '@stimcar/libs-base';
import { useActionCallback } from '@stimcar/libs-uikernel';
import { ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../state/typings/store.js';
import type {
  AddOrUpdateDefectDialogState,
  DeleteRefurbishingOptionDialogState,
} from '../../../typings/store.js';
import { EMPTY_DELETE_REFURBISHING_OPTION_DIALOG_STATE } from '../../../typings/store.js';

export function openDeleteRefurbishingOptionModalAction(
  { actionDispatch }: ActionContext<Store, DeleteRefurbishingOptionDialogState>,
  refurbishingOptionId: string
): void {
  actionDispatch.setValue({
    ...EMPTY_DELETE_REFURBISHING_OPTION_DIALOG_STATE,
    refurbishingOptionId,
    active: true,
  });
}

function deleteRefurbishingOptionAction({
  getState,
  actionDispatch,
}: ActionContext<Store, AddOrUpdateDefectDialogState>): void {
  const { deleteRefurbishingOptionDialogState, refurbishingOptions } = getState();
  const { refurbishingOptionId } = deleteRefurbishingOptionDialogState;
  // Remove option from options array
  // and recompute index for other options

  // We take into account all non deleted options that are not the current one,
  // sort them by the current index, and recalculate all indices
  const payloadForDeletion: DeepPartial<RefurbishingOption> = {
    id: refurbishingOptionId,
    deleted: true,
  };

  const payloadsForIndexRecomputation: readonly DeepPartial<RefurbishingOption>[] =
    refurbishingOptions
      .filter(nonDeleted)
      .filter(({ id }) => id !== refurbishingOptionId)
      .sort(({ index: index1 }, { index: index2 }) => index1 - index2)
      .map(({ id }, index) => {
        return {
          id,
          index: index + 1,
        };
      });

  const fullPayload = [...payloadsForIndexRecomputation, payloadForDeletion];
  actionDispatch.scopeProperty('refurbishingOptions').applyPayload(fullPayload);

  // Then close dialog
  actionDispatch
    .scopeProperty('deleteRefurbishingOptionDialogState')
    .setValue(EMPTY_DELETE_REFURBISHING_OPTION_DIALOG_STATE);
}

type DeleteRefurbishingOptionModalProps = {
  readonly $: StoreStateSelector<Store, AddOrUpdateDefectDialogState>;
};

export function DeleteRefurbishingOptionModal({
  $,
}: DeleteRefurbishingOptionModalProps): JSX.Element {
  const [t] = useTranslation('operators', {
    keyPrefix: 'defects.addOrUpdateDefect.deleteRefurbishingOption',
  });

  const deleteRefurbishingOptionActionCallback = useActionCallback(
    deleteRefurbishingOptionAction,
    [],
    $
  );

  return (
    <ModalCardDialog
      title={t('title')}
      $active={$.$deleteRefurbishingOptionDialogState.$active}
      okLabel={t('okButtonLabel')}
      onOkClicked={deleteRefurbishingOptionActionCallback}
    >
      <p>{t('confirmDeletionMsg')}</p>
    </ModalCardDialog>
  );
}
