/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  Attachment,
  CarElement,
  CarViewCategory,
  ContractAttributeDesc,
  CoreFields,
  MemoDesc,
  MergedCarViewCategory,
  PackageDealExpressionComputationResult,
  PackageDealsBySituation,
  PriceableKanban,
  PriceablePackageDeal,
  PriceableSparePart,
  PurchaseOrder,
} from '@stimcar/libs-base';
import type { ActionContext, AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import {
  expertiseHelpers,
  filterReject,
  nonDeleted,
  packageDealHelpers,
  priceHelpers,
  purchaseOrderHelpers,
  sortingHelpers,
  VATPercentage,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { ComputeAttachmentUrlCallback } from '../../attachments/typings/attachment.js';
import type { ImageModalState } from '../../attachments/typings/store.js';
import { MultilineValueTableCellContent } from '../../../bulma/elements/table/MultilineValueTableCellContent.js';
import { FaIcon, TableActionCell, TableActionHeaderCell, Tabs } from '../../../bulma/index.js';
import { CustomerInformations } from '../../displayInformations/CustomerInformations.js';
import { MemosBloc } from '../../displayInformations/MemosBloc.js';
import { PackageDealRecommendationLogo, StimcarLogo } from '../../images/index.js';
import { DisplayMultilineString, ToggleNestedButton } from '../../misc/index.js';
import { ColumnedCarviewAndAttachmentThumbnailsDisplayer } from '../../photosdisplayer/ColumnedCarviewAndAttachmentThumbnailsDisplayer.js';
import { CarInfo } from '../DocumentGenerationCommonComponents.js';
import type {
  ChangePackageDealLinkedPurchaseOrderModalState,
  EstimatePackageDealsDisplayMode,
  EstimatePackageDealsSortMode,
} from './typings/store.js';
import { ChangePackageDealLinkedPurchaseOrderDialog } from './ChangePackageDealLinkedPurchaseOrderDialog.js';
import {
  EMPTY_CHANGE_PACKAGE_DEAL_LINKED_PURCHASE_ORDER_DATA,
  EMPTY_CHANGE_PACKAGE_DEAL_LINKED_PURCHASE_ORDER_MODAL_STATE,
} from './typings/store.js';

interface TitleProps {
  readonly text: string;
  readonly additionnalClassNames?: string;
}

const Title = ({ text, additionnalClassNames = '' }: TitleProps): JSX.Element => (
  <span className={`is-size-5 has-text-weight-bold ${additionnalClassNames}`}>{text}</span>
);

interface ExpertiseValidationToolkit {
  readonly packageDealsUpdatedInformations: readonly PackageDealExpressionComputationResult[];
  readonly expertiseStandIds: readonly string[];
  readonly unchangeablePackageDealCodes: readonly string[];
  readonly notReferencedSparePartIdsFromAvailablePackageDeals: readonly string[];
  readonly togglePackageDealStatus: (packageDealId: string) => Promise<void> | void;
  readonly changePackageDealLinkedPurchaseOrder: (
    packageDealId: string,
    purchaseOrderId: string
  ) => void;
}

export interface EstimateProps<SD extends AnyStoreDef> {
  readonly kanban: PriceableKanban;
  readonly packageDealsDisplayMode: EstimatePackageDealsDisplayMode;
  readonly $expandedSectionIds: StoreStateSelector<SD, readonly string[]>;
  readonly computeAttachmentUrl: ComputeAttachmentUrlCallback;
  readonly $imageModal: StoreStateSelector<SD, ImageModalState>;
  readonly packageDealsSortMode: EstimatePackageDealsSortMode;
  readonly estimateMention: string;
  readonly attributeDescs: readonly ContractAttributeDesc[];
  readonly memoDescs: Record<CarViewCategory, MemoDesc[]>;
  readonly $changePackageDealLinkedPurchaseOrderModalState: StoreStateSelector<
    SD,
    ChangePackageDealLinkedPurchaseOrderModalState
  >;
  readonly dontShowLocalEstimateId?: boolean;
  readonly showPrices?: boolean;
  readonly expertiseValidationToolkit?: ExpertiseValidationToolkit;
  readonly $selectedPurchaseOrderTab: StoreStateSelector<SD, string>;
}

type ImageDef = Omit<Attachment, 'deleted'> & {
  readonly carElement: CoreFields<CarElement> | undefined;
};

const PHOTO_HEIGHT = 125;

function openPurchaseOrdersDialogAction<SD extends AnyStoreDef>(
  { actionDispatch }: ActionContext<SD, ChangePackageDealLinkedPurchaseOrderModalState>,
  packageDeal: PriceablePackageDeal,
  purchaseOrders: readonly PurchaseOrder[]
): void {
  const selectedPurchaseOrder =
    purchaseOrders.find(({ id }) => id === packageDeal.purchaseOrderId) ?? purchaseOrders[0];
  const selectedPurchaseOrderLabel =
    purchaseOrderHelpers.getPurchaseOrderDisplayedLabel(selectedPurchaseOrder);

  actionDispatch.setValue({
    ...EMPTY_CHANGE_PACKAGE_DEAL_LINKED_PURCHASE_ORDER_MODAL_STATE,
    changePackageDealLinkedPurchaseOrderDialog: {
      active: true,
      packageDealId: packageDeal.id,
      purchaseOrders,
    },
    formData: {
      ...EMPTY_CHANGE_PACKAGE_DEAL_LINKED_PURCHASE_ORDER_DATA,
      selectedPurchaseOrderLabel,
    },
  });
}

export function EstimateComponent<SD extends AnyStoreDef>({
  kanban,
  memoDescs,
  $imageModal,
  attributeDescs,
  estimateMention,
  $expandedSectionIds,
  packageDealsSortMode,
  computeAttachmentUrl,
  packageDealsDisplayMode,
  $selectedPurchaseOrderTab,
  expertiseValidationToolkit,
  $changePackageDealLinkedPurchaseOrderModalState,
  showPrices = true,
  dontShowLocalEstimateId = false,
}: EstimateProps<SD>): JSX.Element {
  const [t] = useTranslation('custom');
  const selectedPurchaseOrderTab = useGetState($selectedPurchaseOrderTab);

  const activePackageDeals: readonly PriceablePackageDeal[] = useMemo(() => {
    let updatedPackageDeals: readonly PriceablePackageDeal[];

    if (isTruthy(expertiseValidationToolkit)) {
      updatedPackageDeals = expertiseHelpers.applyPackageDealsValidationUpdatedInformations(
        kanban.packageDeals.filter(nonDeleted),
        expertiseValidationToolkit.packageDealsUpdatedInformations
      );
    } else {
      updatedPackageDeals = kanban.packageDeals
        .filter(nonDeleted)
        .map((pck): PriceablePackageDeal => {
          if (pck.status === null) {
            return {
              ...pck,
              status: pck.recommendedByExpert ? 'available' : 'canceled',
            };
          }
          return pck;
        });
    }

    // Assert all package deals have a status
    if (updatedPackageDeals.filter((pck) => pck.status === null).length > 0) {
      throw Error('Unexpected Error in estimate computation');
    }

    return updatedPackageDeals;
  }, [expertiseValidationToolkit, kanban.packageDeals]);

  const { selectedPackageDeals, discardedPackageDeals } = useMemo(() => {
    const { filtered, rejected } = filterReject(
      activePackageDeals,
      (pd: PriceablePackageDeal): boolean => packageDealHelpers.isPackageDealAvailable(pd)
    );
    if (packageDealsDisplayMode === 'all') {
      return {
        selectedPackageDeals: [...filtered, ...rejected],
        discardedPackageDeals: [],
      };
    }
    return {
      selectedPackageDeals: filtered,
      discardedPackageDeals: rejected,
    };
  }, [activePackageDeals, packageDealsDisplayMode]);

  const hasMultiplePurchaseOrders = useMemo(
    () => purchaseOrderHelpers.hasMultiplePurchaseOrders(kanban.purchaseOrders),
    [kanban.purchaseOrders]
  );

  const imagesPaths: string[] = [];
  const images: ImageDef[] = [];
  activePackageDeals.forEach(({ carElement, attachments }) => {
    if (attachments) {
      attachments.forEach(({ id, folder, name }) => {
        const existing = images.find((i) => i.folder === folder && i.name === name);
        if (!existing || !existing.carElement) {
          images.push({ id, folder, name, carElement });
          const path = `${folder}/${name}`;
          if (!imagesPaths.includes(path)) {
            imagesPaths.push(path);
          }
        }
      });
    }
  });

  const showNotFullyManagedSparePartMention =
    packageDealHelpers.isNotFullyManagedSparePart(activePackageDeals);

  const additionalMentions: string[] = [];
  if (showNotFullyManagedSparePartMention) {
    additionalMentions.push(t('documentGeneration.estimate.notFullyManagedSparePartMention'));
  }
  if (isTruthyAndNotEmpty(estimateMention)) {
    additionalMentions.push(estimateMention);
  }

  const notReferencedSparePartIdsFromAvailablePackageDeals =
    expertiseValidationToolkit?.notReferencedSparePartIdsFromAvailablePackageDeals ?? [];

  const selectedPackageDealsEstimateTableTitle = t(
    `documentGeneration.estimate.packageDealsInformations.${
      packageDealsDisplayMode === 'retained' ? 'validatedTitle' : 'allPackageDealsTitle'
    }`
  );

  const canTogglePackageDealStatus = useMemo(
    () => packageDealsDisplayMode === 'retained',
    [packageDealsDisplayMode]
  );

  const showVAT = useMemo(
    () =>
      !kanban.customer.ignoreVAT && packageDealHelpers.hasPackageDealWithVAT(selectedPackageDeals),
    [kanban.customer.ignoreVAT, selectedPackageDeals]
  );

  const customerToDisplay = useMemo(() => {
    const activePurchaseOrderCustomer =
      purchaseOrderHelpers.getPurchaseOrderCustomerForPurchaseNumber(
        kanban.purchaseOrders,
        selectedPurchaseOrderTab
      );
    return isTruthy(activePurchaseOrderCustomer) ? activePurchaseOrderCustomer : kanban.customer;
  }, [kanban, selectedPurchaseOrderTab]);

  return (
    <>
      <div className="columns has-text-centered">
        <div
          className="column is-fullwidth"
          style={{ border: '0.0625em solid', marginRight: '0.5em', marginLeft: '0.5em' }}
        >
          <p className="title">
            {t(`documentGeneration.estimate.${showPrices ? 'estimate' : 'plannedWork'}Title`)}
            {!dontShowLocalEstimateId && (
              <>
                {`${t('documentGeneration.estimate.number')} : ${new Date()
                  .toISOString()
                  .replace(/-/g, '')
                  .replace(/T.*/, '')}-${kanban.id.replace(/:/g, '-').replace(/-.$/g, '')}`}
              </>
            )}
          </p>
        </div>
      </div>
      <div className="columns">
        <div className="column is-8">
          <CarInfo attributeDescs={attributeDescs} kanban={kanban} />
        </div>
        <div className="column">
          <CustomerInformations customer={customerToDisplay} />
        </div>
      </div>
      {kanban.estimateComment && (
        <>
          <Title text={t('kanbanGeneralInformations.estimateComment')} />
          <div className="columns p-t-md">
            <div className="column ml-1">
              <DisplayMultilineString
                value={kanban.estimateComment}
                placeholder={t('custom:memo.emptyMemo')}
              />
            </div>
          </div>
        </>
      )}
      {hasMultiplePurchaseOrders ? (
        <PurchaseOrdersEstimates
          kanbanId={kanban.id}
          showPrices={showPrices}
          $imageModal={$imageModal}
          $selectedPurchaseOrderTab={$selectedPurchaseOrderTab}
          showVAT={showVAT}
          $expandedSectionIds={$expandedSectionIds}
          computeAttachmentUrl={computeAttachmentUrl}
          purchaseOrders={kanban.purchaseOrders}
          packageDealsSortMode={packageDealsSortMode}
          $changePackageDealLinkedPurchaseOrderModalState={
            $changePackageDealLinkedPurchaseOrderModalState
          }
          canTogglePackageDealStatus={canTogglePackageDealStatus}
          selectedPackageDeals={selectedPackageDeals}
          discardedPackageDeals={discardedPackageDeals}
          expertiseValidationToolkit={expertiseValidationToolkit}
          selectedPackageDealsEstimateTableTitle={selectedPackageDealsEstimateTableTitle}
        />
      ) : (
        <>
          {selectedPackageDeals.length > 0 && (
            <PackageDealEstimateTableComponent
              kanbanId={kanban.id}
              showPrices={showPrices}
              $imageModal={$imageModal}
              showVAT={showVAT}
              packageDeals={selectedPackageDeals}
              $expandedSectionIds={$expandedSectionIds}
              computeAttachmentUrl={computeAttachmentUrl}
              packageDealsSortMode={packageDealsSortMode}
              title={selectedPackageDealsEstimateTableTitle}
              canTogglePackageDealStatus={canTogglePackageDealStatus}
              expertiseValidationToolkit={expertiseValidationToolkit}
            />
          )}
          {discardedPackageDeals.length > 0 && (
            <PackageDealEstimateTableComponent
              kanbanId={kanban.id}
              showPrices={showPrices}
              $imageModal={$imageModal}
              showVAT={showVAT}
              $expandedSectionIds={$expandedSectionIds}
              packageDeals={discardedPackageDeals}
              computeAttachmentUrl={computeAttachmentUrl}
              packageDealsSortMode={packageDealsSortMode}
              canTogglePackageDealStatus={canTogglePackageDealStatus}
              title={t('documentGeneration.estimate.packageDealsInformations.discardedTitle')}
              expertiseValidationToolkit={expertiseValidationToolkit}
            />
          )}
        </>
      )}
      <Title text={t('memo.title')} />
      <div className="columns p-t-md">
        <div className="column">
          <MemosBloc
            memos={kanban.memos ?? {}}
            memoDescs={memoDescs}
            showOnlyEstimateMemos
            numberOfColumns={3}
          />
        </div>
      </div>
      <div className="m-b-md">
        <strong>{t('documentGeneration.endOfDocumentMentions.title')}</strong>
        <StimcarIconMention recommended />
        {notReferencedSparePartIdsFromAvailablePackageDeals.length > 0 && (
          <NotReferencedSparePartMention />
        )}
        {additionalMentions.map((m) => (
          <div key={m}>{`- ${m}`}</div>
        ))}
      </div>
      <ChangePackageDealLinkedPurchaseOrderDialog
        $={$changePackageDealLinkedPurchaseOrderModalState}
        changePackageDealLinkedPurchaseOrder={
          expertiseValidationToolkit?.changePackageDealLinkedPurchaseOrder
        }
      />
    </>
  );
}

interface PurchaseOrdersEstimatesProps<SD extends AnyStoreDef> {
  readonly kanbanId: string;
  readonly showVAT: boolean;
  readonly showPrices: boolean;
  readonly canTogglePackageDealStatus: boolean;
  readonly purchaseOrders: readonly PurchaseOrder[];
  readonly selectedPackageDealsEstimateTableTitle: string;
  readonly computeAttachmentUrl: ComputeAttachmentUrlCallback;
  readonly packageDealsSortMode: EstimatePackageDealsSortMode;
  readonly $imageModal: StoreStateSelector<SD, ImageModalState>;
  readonly $selectedPurchaseOrderTab: StoreStateSelector<SD, string>;
  readonly selectedPackageDeals: readonly PriceablePackageDeal[];
  readonly discardedPackageDeals: readonly PriceablePackageDeal[];
  readonly $expandedSectionIds: StoreStateSelector<SD, readonly string[]>;
  readonly expertiseValidationToolkit: ExpertiseValidationToolkit | undefined;
  readonly $changePackageDealLinkedPurchaseOrderModalState: StoreStateSelector<
    SD,
    ChangePackageDealLinkedPurchaseOrderModalState
  >;
}

function PurchaseOrdersEstimates<SD extends AnyStoreDef>({
  showVAT,
  kanbanId,
  showPrices,
  $imageModal,
  purchaseOrders,
  $expandedSectionIds,
  computeAttachmentUrl,
  packageDealsSortMode,
  selectedPackageDeals,
  discardedPackageDeals,
  $selectedPurchaseOrderTab,
  canTogglePackageDealStatus,
  expertiseValidationToolkit,
  selectedPackageDealsEstimateTableTitle,
  $changePackageDealLinkedPurchaseOrderModalState,
}: PurchaseOrdersEstimatesProps<SD>): JSX.Element {
  const [t] = useTranslation('custom');

  const activePurchaseOrders = useMemo(() => purchaseOrders.filter(nonDeleted), [purchaseOrders]);

  const packageDealsByPurchaseOrders = useMemo(
    () =>
      Object.entries(
        purchaseOrderHelpers.getPackageDealsWithSituationByPurchaseOrder(
          activePurchaseOrders,
          {
            SELECTED: selectedPackageDeals,
            DISCARDED: discardedPackageDeals,
          },
          false
        )
      ),
    [selectedPackageDeals, discardedPackageDeals, activePurchaseOrders]
  );

  const notAllocatedPackageDeals = useMemo(
    () =>
      purchaseOrderHelpers.getNotAllocatedPackageDealsWithSituation({
        SELECTED: selectedPackageDeals,
        DISCARDED: discardedPackageDeals,
      }),
    [selectedPackageDeals, discardedPackageDeals]
  );

  const notAllocatedTabLabel = useMemo(() => {
    const tabLabel = t('documentGeneration.estimate.packageDealsInformations.notAllocated');
    if (showPrices) {
      const priceLabelWithoutVAT = t(
        'documentGeneration.estimate.packageDealsInformations.totalForPurchaseOrderWithoutVAT',
        {
          total: packageDealHelpers
            .pdStatusRegardlesslyGetTotalPriceOperationsAndSpareParts(
              notAllocatedPackageDeals.SELECTED,
              'all'
            )
            .toFixed(2),
        }
      );
      return `${tabLabel} ${priceLabelWithoutVAT}`;
    }
    return tabLabel;
  }, [t, showPrices, notAllocatedPackageDeals.SELECTED]);

  const selectedPurchaseOrderTab = useGetState($selectedPurchaseOrderTab);

  const purchaseOrdersTabsLabels = useMemo((): Record<string, string> => {
    const tabsLabels = packageDealsByPurchaseOrders.reduce(
      (accumulator, [purchaseNumber, { SELECTED }]) => {
        const tabLabelSuffix = showPrices
          ? ` ${t(
              'documentGeneration.estimate.packageDealsInformations.totalForPurchaseOrderWithoutVAT',
              {
                total: packageDealHelpers
                  .pdStatusRegardlesslyGetTotalPriceOperationsAndSpareParts(SELECTED, 'all')
                  .toFixed(2),
              }
            )}`
          : '';
        return {
          ...accumulator,
          [purchaseNumber]: `${purchaseNumber}${tabLabelSuffix}`,
        };
      },
      {} as Record<string, string>
    );

    const notAllocatedPackageDealsTotalPrice =
      packageDealHelpers.pdStatusRegardlesslyGetTotalPriceOperationsAndSpareParts(
        notAllocatedPackageDeals.SELECTED,
        'all'
      );

    if (notAllocatedPackageDealsTotalPrice === 0) {
      return tabsLabels;
    }

    return { ...tabsLabels, [notAllocatedTabLabel]: notAllocatedTabLabel };
  }, [t, packageDealsByPurchaseOrders, showPrices, notAllocatedTabLabel, notAllocatedPackageDeals]);

  const purchaseOrdersTabsLabelsSortingFunction = useCallback(
    (a: string, b: string) => {
      if (a === notAllocatedTabLabel) {
        return 1;
      }
      return a.localeCompare(b);
    },
    [notAllocatedTabLabel]
  );

  const purchaseOrdersToDisplay = useMemo((): [string, PackageDealsBySituation][] => {
    if (selectedPurchaseOrderTab === notAllocatedTabLabel) {
      return [[notAllocatedTabLabel, notAllocatedPackageDeals]];
    }
    const packageDealsForPurchaseOrder = packageDealsByPurchaseOrders.find(
      ([purchageOrderLabel]) => purchageOrderLabel === selectedPurchaseOrderTab
    );
    return packageDealsForPurchaseOrder ? [packageDealsForPurchaseOrder] : [];
  }, [
    notAllocatedTabLabel,
    selectedPurchaseOrderTab,
    notAllocatedPackageDeals,
    packageDealsByPurchaseOrders,
  ]);

  const openPurchaseOrdersDialog = useActionCallback(
    async ({ actionDispatch }, packageDeal: PriceablePackageDeal) =>
      actionDispatch.exec(openPurchaseOrdersDialogAction, packageDeal, activePurchaseOrders),
    [activePurchaseOrders],
    $changePackageDealLinkedPurchaseOrderModalState
  );

  const hasNothingToDisplayForTab = useCallback(
    (
      selectedPackageDeals: readonly PriceablePackageDeal[],
      discardedPackageDeals: readonly PriceablePackageDeal[]
    ) => selectedPackageDeals.length === 0 && discardedPackageDeals.length === 0,
    []
  );

  const getEmptyTabLabel = useCallback(
    () =>
      selectedPurchaseOrderTab === notAllocatedTabLabel
        ? t('documentGeneration.estimate.packageDealsInformations.noNotAllocatedPackageDeals')
        : t('documentGeneration.estimate.packageDealsInformations.noPackageDealsForPurchaseOrder'),
    [selectedPurchaseOrderTab, notAllocatedTabLabel, t]
  );

  const tabTitle = useMemo(() => {
    const selectedPurchaseOrder = activePurchaseOrders.find(
      ({ purchaseNumber }) => purchaseNumber === selectedPurchaseOrderTab
    );
    if (selectedPurchaseOrder) {
      return purchaseOrderHelpers.getPurchaseOrderDisplayedLabel(selectedPurchaseOrder);
    }
    return '';
  }, [activePurchaseOrders, selectedPurchaseOrderTab]);

  return (
    <div className="mb-4">
      <Tabs
        isCentered
        className="mb-4"
        labels={purchaseOrdersTabsLabels}
        $selectedTab={$selectedPurchaseOrderTab}
        sortingFunction={purchaseOrdersTabsLabelsSortingFunction}
      />
      {selectedPurchaseOrderTab !== notAllocatedTabLabel && (
        <h5 className="title is-5 mb-4">{tabTitle}</h5>
      )}
      {purchaseOrdersToDisplay.map(
        ([
          purchaseOrderDisplayedLabel,
          { SELECTED: selectedPackageDeals, DISCARDED: discardedPackageDeals },
        ]) => (
          <div key={purchaseOrderDisplayedLabel}>
            {selectedPackageDeals.length > 0 && (
              <PackageDealEstimateTableComponent
                showVAT={showVAT}
                kanbanId={kanbanId}
                showPrices={showPrices}
                $imageModal={$imageModal}
                hasMultiplePurchaseOrders
                packageDeals={selectedPackageDeals}
                $expandedSectionIds={$expandedSectionIds}
                computeAttachmentUrl={computeAttachmentUrl}
                packageDealsSortMode={packageDealsSortMode}
                title={selectedPackageDealsEstimateTableTitle}
                openPurchaseOrdersDialog={openPurchaseOrdersDialog}
                canTogglePackageDealStatus={canTogglePackageDealStatus}
                expertiseValidationToolkit={expertiseValidationToolkit}
              />
            )}
            {discardedPackageDeals.length > 0 && (
              <PackageDealEstimateTableComponent
                showVAT={showVAT}
                kanbanId={kanbanId}
                showPrices={showPrices}
                $imageModal={$imageModal}
                hasMultiplePurchaseOrders
                packageDeals={discardedPackageDeals}
                $expandedSectionIds={$expandedSectionIds}
                computeAttachmentUrl={computeAttachmentUrl}
                packageDealsSortMode={packageDealsSortMode}
                openPurchaseOrdersDialog={openPurchaseOrdersDialog}
                canTogglePackageDealStatus={canTogglePackageDealStatus}
                expertiseValidationToolkit={expertiseValidationToolkit}
                title={t('documentGeneration.estimate.packageDealsInformations.discardedTitle')}
              />
            )}
            {hasNothingToDisplayForTab(selectedPackageDeals, discardedPackageDeals) && (
              <p className="has-text-weight-bold">{getEmptyTabLabel()}</p>
            )}
          </div>
        )
      )}
    </div>
  );
}

interface StimcarIconMentionProps {
  readonly recommended: boolean;
}

function StimcarIconMention({ recommended }: StimcarIconMentionProps): JSX.Element {
  const [t] = useTranslation('custom');

  return (
    <div>
      <span>- </span>
      <StimcarLogo blackAndWhite={!recommended} />
      <span className="m-l-xs">
        {`: ${t(`documentGeneration.estimate.stimcar${recommended ? 'On' : 'Off'}IconMention`)}`}
      </span>
    </div>
  );
}

interface SparePartLabelCell {
  readonly label: string;
  readonly showNotReferencedIcon: boolean;
}

function SparePartLabelCell({ label, showNotReferencedIcon }: SparePartLabelCell): JSX.Element {
  return (
    <div>
      <span>- </span>
      {showNotReferencedIcon && (
        <FaIcon id="exclamation-triangle" iconColor="orange" size="small-xs" />
      )}
      <span className="m-l-xs">{label}</span>
    </div>
  );
}

function NotReferencedSparePartMention(): JSX.Element {
  const [t] = useTranslation('custom');

  return (
    <SparePartLabelCell
      label={`: ${t('documentGeneration.estimate.notReferencedAvailableSparePartsMention')}`}
      showNotReferencedIcon
    />
  );
}

interface PackageDealEstimateTableComponentProps<SD extends AnyStoreDef> {
  readonly title: string;
  readonly showVAT: boolean;
  readonly kanbanId: string;
  readonly showPrices: boolean;
  readonly packageDeals: readonly PriceablePackageDeal[];
  readonly computeAttachmentUrl: ComputeAttachmentUrlCallback;
  readonly packageDealsSortMode: EstimatePackageDealsSortMode;
  readonly $imageModal: StoreStateSelector<SD, ImageModalState>;
  readonly canTogglePackageDealStatus: boolean;
  readonly $expandedSectionIds: StoreStateSelector<SD, readonly string[]>;
  readonly expertiseValidationToolkit: ExpertiseValidationToolkit | undefined;
  readonly hasMultiplePurchaseOrders?: boolean;
  readonly openPurchaseOrdersDialog?: (packageDeal: PriceablePackageDeal) => void;
}

function PackageDealEstimateTableComponent<SD extends AnyStoreDef>({
  title,
  showVAT,
  kanbanId,
  showPrices,
  $imageModal,
  packageDeals,
  $expandedSectionIds,
  packageDealsSortMode,
  computeAttachmentUrl,
  openPurchaseOrdersDialog,
  canTogglePackageDealStatus,
  expertiseValidationToolkit,
  hasMultiplePurchaseOrders = false,
}: PackageDealEstimateTableComponentProps<SD>): JSX.Element {
  const [t] = useTranslation('custom');

  const totalWithoutVAT = useMemo(
    () =>
      packageDealHelpers.pdStatusRegardlesslyGetTotalPriceOperationsAndSpareParts(
        packageDeals,
        'all'
      ),
    [packageDeals]
  );
  const VAT = useMemo(() => {
    return packageDealHelpers.getVATTotalPriceOperationsAndSpareParts(packageDeals, 'all');
  }, [packageDeals]);

  const totalWithVAT = useMemo(() => totalWithoutVAT + VAT, [totalWithoutVAT, VAT]);
  const isEditable = useMemo(
    () => isTruthy(expertiseValidationToolkit),
    [expertiseValidationToolkit]
  );
  const leftFooterColSpan = useMemo(() => (showVAT ? 7 : 5), [showVAT]);

  return (
    <>
      <p>
        <Title text={title} />
      </p>
      <table className="table is-bordered is-fullwidth is-narrow">
        <thead>
          <tr>
            <th style={{ width: '20%' }}>
              {t('documentGeneration.estimate.packageDealsInformations.carElement')}
            </th>
            <TableActionHeaderCell />
            <th style={{ width: '31%' }}>
              {t('documentGeneration.estimate.packageDealsInformations.label')}
            </th>
            <th style={{ width: '1%', whiteSpace: 'nowrap' }}>
              {t('documentGeneration.estimate.packageDealsInformations.quantity')}
            </th>
            <th>
              {t('documentGeneration.estimate.packageDealsInformations.estimateCommentTitle')}
            </th>
            {showPrices && (
              <>
                <th style={{ width: '1%', whiteSpace: 'nowrap' }}>
                  {t('documentGeneration.estimate.packageDealsInformations.costWithoutVAT')}
                </th>
                {showVAT && (
                  <>
                    <th style={{ width: '1%', whiteSpace: 'nowrap' }}>
                      {t('documentGeneration.estimate.packageDealsInformations.VATTitle', {
                        percentage: VATPercentage,
                      })}
                    </th>
                    <th style={{ width: '1%', whiteSpace: 'nowrap' }}>
                      {t('documentGeneration.estimate.packageDealsInformations.costWithVAT')}
                    </th>
                  </>
                )}
              </>
            )}

            {isEditable && (
              <>
                {canTogglePackageDealStatus && (
                  <TableActionHeaderCell additionalClass="no-printing" />
                )}
                {hasMultiplePurchaseOrders && (
                  <TableActionHeaderCell additionalClass="no-printing" />
                )}
              </>
            )}
          </tr>
        </thead>
        <tbody>
          <PackageDealEstimateTableRowsComponent
            kanbanId={kanbanId}
            isEditable={isEditable}
            showPrices={showPrices}
            showVAT={showVAT}
            $imageModal={$imageModal}
            packageDeals={packageDeals}
            $expandedSectionIds={$expandedSectionIds}
            packageDealsSortMode={packageDealsSortMode}
            computeAttachmentUrl={computeAttachmentUrl}
            openPurchaseOrdersDialog={openPurchaseOrdersDialog}
            hasMultiplePurchaseOrders={hasMultiplePurchaseOrders}
            canTogglePackageDealStatus={canTogglePackageDealStatus}
            expertiseValidationToolkit={expertiseValidationToolkit}
          />
        </tbody>
        {showPrices && (
          <tfoot>
            <tr>
              <th className="has-text-right" colSpan={leftFooterColSpan}>
                {t('documentGeneration.estimate.packageDealsInformations.totalWithoutVAT')}
              </th>
              <th className="has-text-right">
                {t('documentGeneration.estimate.packageDealsInformations.price', {
                  amount: totalWithoutVAT.toFixed(2),
                })}
              </th>
              {isEditable && (
                <>
                  {canTogglePackageDealStatus && (
                    <TableActionHeaderCell additionalClass="no-printing" />
                  )}
                  {hasMultiplePurchaseOrders && (
                    <TableActionHeaderCell additionalClass="no-printing" />
                  )}
                </>
              )}
            </tr>
            {showVAT && (
              <>
                <tr>
                  <th className="has-text-right" colSpan={leftFooterColSpan}>
                    {t('documentGeneration.estimate.packageDealsInformations.totalVAT', {
                      percentage: VATPercentage,
                    })}
                  </th>
                  <th className="has-text-right">
                    {t('documentGeneration.estimate.packageDealsInformations.price', {
                      amount: VAT.toFixed(2),
                    })}
                  </th>
                  {isEditable && (
                    <>
                      {canTogglePackageDealStatus && (
                        <TableActionHeaderCell additionalClass="no-printing" />
                      )}
                      {hasMultiplePurchaseOrders && (
                        <TableActionHeaderCell additionalClass="no-printing" />
                      )}
                    </>
                  )}
                </tr>
                <tr>
                  <th className="has-text-right" colSpan={leftFooterColSpan}>
                    {t('documentGeneration.estimate.packageDealsInformations.totalWithVAT')}
                  </th>
                  <th className="has-text-right">
                    {t('documentGeneration.estimate.packageDealsInformations.price', {
                      amount: totalWithVAT.toFixed(2),
                    })}
                  </th>
                  {isEditable && (
                    <>
                      {canTogglePackageDealStatus && (
                        <TableActionHeaderCell additionalClass="no-printing" />
                      )}
                      {hasMultiplePurchaseOrders && (
                        <TableActionHeaderCell additionalClass="no-printing" />
                      )}
                    </>
                  )}
                </tr>
              </>
            )}
          </tfoot>
        )}
      </table>
    </>
  );
}

type PackageDealEstimateTableRowsComponentProps<SD extends AnyStoreDef> = Omit<
  PackageDealEstimateTableComponentProps<SD>,
  'title'
> & {
  readonly isEditable: boolean;
  readonly hasMultiplePurchaseOrders: boolean;
};

function PackageDealEstimateTableRowsComponent<SD extends AnyStoreDef>({
  kanbanId,
  showPrices,
  showVAT,
  isEditable,
  $imageModal,
  packageDeals,
  $expandedSectionIds,
  computeAttachmentUrl,
  packageDealsSortMode,
  openPurchaseOrdersDialog,
  hasMultiplePurchaseOrders,
  canTogglePackageDealStatus,
  expertiseValidationToolkit,
}: PackageDealEstimateTableRowsComponentProps<SD>): JSX.Element {
  const thumbnailsRowColspan = useMemo(() => {
    if (isEditable) {
      if (hasMultiplePurchaseOrders && canTogglePackageDealStatus) {
        return showPrices ? 8 : 7;
      }
      return showPrices ? 7 : 6;
    }
    return showPrices ? 6 : 5;
  }, [hasMultiplePurchaseOrders, showPrices, isEditable, canTogglePackageDealStatus]);

  if (packageDealsSortMode === 'byCategory') {
    return (
      <PackageDealRowsWithCategories
        kanbanId={kanbanId}
        showPrices={showPrices}
        showVAT={showVAT}
        $imageModal={$imageModal}
        packageDeals={packageDeals}
        thumbnailsRowColspan={thumbnailsRowColspan}
        $expandedSectionIds={$expandedSectionIds}
        packageDealsSortMode={packageDealsSortMode}
        computeAttachmentUrl={computeAttachmentUrl}
        openPurchaseOrdersDialog={openPurchaseOrdersDialog}
        hasMultiplePurchaseOrders={hasMultiplePurchaseOrders}
        canTogglePackageDealStatus={canTogglePackageDealStatus}
        expertiseValidationToolkit={expertiseValidationToolkit}
      />
    );
  }

  const sortedPackageDeals = packageDeals
    .slice()
    .sort(sortingHelpers.createSortPackageDealByCarElementIndexThenLabelFunction('DOWN'));
  return (
    <>
      {sortedPackageDeals.map((packageDeal) => (
        <PackageDealRowComponent
          key={packageDeal.id}
          kanbanId={kanbanId}
          showPrices={showPrices}
          showVAT={showVAT}
          $imageModal={$imageModal}
          packageDeal={packageDeal}
          $expandedSectionIds={$expandedSectionIds}
          packageDealsSortMode={packageDealsSortMode}
          thumbnailsRowColspan={thumbnailsRowColspan}
          computeAttachmentUrl={computeAttachmentUrl}
          openPurchaseOrdersDialog={openPurchaseOrdersDialog}
          hasMultiplePurchaseOrders={hasMultiplePurchaseOrders}
          canTogglePackageDealStatus={canTogglePackageDealStatus}
          expertiseValidationToolkit={expertiseValidationToolkit}
        />
      ))}
    </>
  );
}

type PackageDealRowsWithCategoriesProps<SD extends AnyStoreDef> = Omit<
  PackageDealEstimateTableRowsComponentProps<SD>,
  'isEditable'
> & { readonly thumbnailsRowColspan: number };

function PackageDealRowsWithCategories<SD extends AnyStoreDef>({
  kanbanId,
  showPrices,
  showVAT,
  $imageModal,
  packageDeals,
  $expandedSectionIds,
  computeAttachmentUrl,
  packageDealsSortMode,
  thumbnailsRowColspan,
  openPurchaseOrdersDialog,
  hasMultiplePurchaseOrders,
  canTogglePackageDealStatus,
  expertiseValidationToolkit,
}: PackageDealRowsWithCategoriesProps<SD>): JSX.Element {
  const [t] = useTranslation('custom');

  const packageDealsByCarElementsCategories = useMemo(() => {
    const packageDealsByCarElementsCategories = packageDeals.reduce(
      (accumulator, current) => {
        const categoryCode = packageDealHelpers.getMergedCategoryCode(current);
        const packageDealsForCategory = accumulator[categoryCode]
          ? [...accumulator[categoryCode], current]
          : [current];
        return { ...accumulator, [categoryCode]: packageDealsForCategory };
      },
      {} as Record<MergedCarViewCategory, readonly PriceablePackageDeal[]>
    );

    const packageDealsByCarElementsCategoriesEntries = Object.entries(
      packageDealsByCarElementsCategories
    ) as readonly [MergedCarViewCategory, readonly PriceablePackageDeal[]][];

    return packageDealsByCarElementsCategoriesEntries
      .slice()
      .sort(([code1], [code2]) =>
        packageDealHelpers.compareCarElementMergedCategories(t, code1, code2)
      );
  }, [packageDeals, t]);

  return (
    <>
      {packageDealsByCarElementsCategories.map(
        ([categoryCode, packageDeals]) =>
          packageDeals.length > 0 && (
            <React.Fragment key={categoryCode}>
              <tr className="has-background-grey-lighter has-text-weight-semibold">
                <td colSpan={thumbnailsRowColspan}>
                  {packageDealHelpers.getMergedCategoryLabelFromCategoryCode(categoryCode, t)}
                </td>
              </tr>
              {packageDeals.map((packageDeal) => (
                <PackageDealRowComponent
                  key={packageDeal.id}
                  kanbanId={kanbanId}
                  showPrices={showPrices}
                  showVAT={showVAT}
                  $imageModal={$imageModal}
                  packageDeal={packageDeal}
                  $expandedSectionIds={$expandedSectionIds}
                  packageDealsSortMode={packageDealsSortMode}
                  thumbnailsRowColspan={thumbnailsRowColspan}
                  computeAttachmentUrl={computeAttachmentUrl}
                  openPurchaseOrdersDialog={openPurchaseOrdersDialog}
                  hasMultiplePurchaseOrders={hasMultiplePurchaseOrders}
                  canTogglePackageDealStatus={canTogglePackageDealStatus}
                  expertiseValidationToolkit={expertiseValidationToolkit}
                />
              ))}
            </React.Fragment>
          )
      )}
    </>
  );
}

type PackageDealRowComponentProps<SD extends AnyStoreDef> = Omit<
  PackageDealEstimateTableRowsComponentProps<SD>,
  'packageDeals' | 'isEditable'
> & {
  readonly packageDeal: PriceablePackageDeal;
  readonly thumbnailsRowColspan: number;
};

function PackageDealRowComponent<SD extends AnyStoreDef>({
  kanbanId,
  showPrices,
  showVAT,
  $imageModal,
  packageDeal,
  $expandedSectionIds,
  computeAttachmentUrl,
  thumbnailsRowColspan,
  openPurchaseOrdersDialog,
  hasMultiplePurchaseOrders,
  canTogglePackageDealStatus,
  expertiseValidationToolkit,
}: PackageDealRowComponentProps<SD>): JSX.Element {
  const hasSelectionColumn = isTruthy(expertiseValidationToolkit);

  const attachments = useMemo(
    () => (packageDeal.attachments ?? []).filter(nonDeleted),
    [packageDeal]
  );

  const nonDeletedSpareParts = useMemo(
    () => packageDeal.spareParts.filter(nonDeleted),
    [packageDeal.spareParts]
  );

  const expandedSectionIds = useGetState($expandedSectionIds);
  return (
    <React.Fragment key={packageDeal.id}>
      <SinglePackageDealRowComponent
        packageDeal={packageDeal}
        showPrices={showPrices}
        showVAT={showVAT}
        $expandedSectionIds={$expandedSectionIds}
        canTogglePackageDealStatus={canTogglePackageDealStatus}
        expertiseValidationToolkit={expertiseValidationToolkit}
        openPurchaseOrdersDialog={openPurchaseOrdersDialog}
        hasMultiplePurchaseOrders={hasMultiplePurchaseOrders}
      />
      {expandedSectionIds.includes(packageDeal.id) && (
        <tr>
          <td colSpan={thumbnailsRowColspan}>
            <ColumnedCarviewAndAttachmentThumbnailsDisplayer
              category={packageDeal.carElement?.category ?? 'MISC'}
              selectedShapes={packageDeal.carElement?.shapes ?? []}
              attachments={attachments}
              computeAttachmentUrl={computeAttachmentUrl}
              $imageModal={$imageModal}
              kanbanId={kanbanId}
              photoHeight={PHOTO_HEIGHT}
            />
          </td>
          {hasSelectionColumn && <td className="no-printing" />}
        </tr>
      )}
      {nonDeletedSpareParts.map((sparePart) => (
        <SingleSparePartRowComponent
          key={sparePart.id}
          sparePart={sparePart}
          packageDeal={packageDeal}
          showVAT={showVAT}
          showPrices={showPrices}
          hasSelectionColumn={hasSelectionColumn}
          hasMultiplePurchaseOrders={hasMultiplePurchaseOrders}
          canTogglePackageDealStatus={canTogglePackageDealStatus}
          notReferencedSparePartIdsFromAvailablePackageDeals={
            expertiseValidationToolkit?.notReferencedSparePartIdsFromAvailablePackageDeals
          }
        />
      ))}
    </React.Fragment>
  );
}

interface SinglePackageDealRowComponentProps<SD extends AnyStoreDef> {
  readonly showPrices: boolean;
  readonly showVAT: boolean;
  readonly packageDeal: PriceablePackageDeal;
  readonly hasMultiplePurchaseOrders: boolean;
  readonly canTogglePackageDealStatus: boolean;
  readonly $expandedSectionIds: StoreStateSelector<SD, readonly string[]>;
  readonly expertiseValidationToolkit: ExpertiseValidationToolkit | undefined;
  readonly openPurchaseOrdersDialog?: (packageDeal: PriceablePackageDeal) => void;
}

function SinglePackageDealRowComponent<SD extends AnyStoreDef>({
  showPrices,
  showVAT,
  packageDeal,
  $expandedSectionIds,
  hasMultiplePurchaseOrders,
  canTogglePackageDealStatus,
  expertiseValidationToolkit,
  openPurchaseOrdersDialog: openPurchaseOrdersDialogCallback,
}: SinglePackageDealRowComponentProps<SD>): JSX.Element {
  const [t] = useTranslation('custom');

  const attachments = useMemo(
    () => (packageDeal.attachments ?? []).filter(nonDeleted),
    [packageDeal]
  );

  const isTableEditable = useMemo(
    () => isTruthy(expertiseValidationToolkit),
    [expertiseValidationToolkit]
  );

  const [isStatusActionDisabled, toggleStatusTooltip] = useMemo(() => {
    if (packageDeal.status === 'available') {
      if (
        (expertiseValidationToolkit?.unchangeablePackageDealCodes ?? []).includes(packageDeal.code)
      ) {
        return [
          true,
          t('documentGeneration.estimate.tooltips.pckCannotBeCanceledBecauseOfUnmodifiableList'),
        ];
      }
      if (packageDealHelpers.isPackageDealStartedOrUnremovable(packageDeal)) {
        return [
          true,
          t('documentGeneration.estimate.tooltips.pckCannotBeCanceledBecauseAlreadyStarted'),
        ];
      }
      return [false, t('documentGeneration.estimate.tooltips.markPckAsCanceled')];
    }

    if (packageDealHelpers.isExpertisePackageDeal(packageDeal)) {
      return [
        true,
        t('documentGeneration.estimate.tooltips.cannotMakeAvailableBecauseExpertisePackage'),
      ];
    }

    return [false, t('documentGeneration.estimate.tooltips.marcPckAsAvailable')];
  }, [expertiseValidationToolkit, packageDeal, t]);

  const changePackageDealStatus = useCallback(async () => {
    await expertiseValidationToolkit?.togglePackageDealStatus(packageDeal.id);
  }, [expertiseValidationToolkit, packageDeal.id]);

  const openPurchaseOrdersDialog = useCallback(() => {
    if (openPurchaseOrdersDialogCallback) {
      openPurchaseOrdersDialogCallback(packageDeal);
    }
  }, [openPurchaseOrdersDialogCallback, packageDeal]);

  const isRowWithVAT = useMemo(
    () => packageDealHelpers.isPackageDealWithVAT(packageDeal),
    [packageDeal]
  );

  const priceWithoutVAT = useMemo(
    () => priceHelpers.getPriceWithoutVAT(packageDeal),
    [packageDeal]
  );
  const priceWithVAT = useMemo(() => priceHelpers.getPriceWithVAT(packageDeal), [packageDeal]);

  return (
    <tr>
      <td>
        <ToggleNestedButton
          id={packageDeal.id}
          hasChildren={attachments.length > 0}
          $expandedIds={$expandedSectionIds}
          label={
            packageDeal.carElement
              ? packageDeal.carElement.label
              : t('documentGeneration.estimate.miscPackageDealCarElementPlaceholder')
          }
        />
      </td>
      <td>
        {packageDeal.recommendedByExpert && <PackageDealRecommendationLogo recommendedByExpert />}
      </td>
      <td>{packageDealHelpers.getPackageDealDisplayedLabel(packageDeal)}</td>
      <td />
      <td>
        <MultilineValueTableCellContent value={packageDeal.estimateComment} />
      </td>
      {showPrices && (
        <>
          <td className="has-text-right">
            {t('documentGeneration.estimate.packageDealsInformations.price', {
              amount: priceWithoutVAT.toFixed(2),
            })}
          </td>
          {showVAT && (
            <>
              <td className="has-text-right">
                {t('documentGeneration.estimate.packageDealsInformations.VAT', {
                  percentage: isRowWithVAT ? VATPercentage : 0,
                })}
              </td>
              <td className="has-text-right">
                {t('documentGeneration.estimate.packageDealsInformations.price', {
                  amount: isRowWithVAT ? priceWithVAT.toFixed(2) : priceWithoutVAT.toFixed(2),
                })}
              </td>
            </>
          )}
        </>
      )}
      {isTableEditable && (
        <>
          {canTogglePackageDealStatus && (
            <TableActionCell
              iconId={packageDeal.status === 'available' ? 'chevron-down' : 'chevron-up'}
              onClick={changePackageDealStatus}
              disabled={isStatusActionDisabled}
              additionalTdClassName="no-printing"
              tooltip={toggleStatusTooltip}
            />
          )}
          {hasMultiplePurchaseOrders && (
            <TableActionCell
              iconId="scale-balanced"
              onClick={openPurchaseOrdersDialog}
              additionalTdClassName="no-printing"
              tooltip={t(
                'documentGeneration.estimate.purchaseOrders.ventilatePackageOnPurchaseOrder'
              )}
            />
          )}
        </>
      )}
    </tr>
  );
}

interface SingleSparePartRowComponentProps {
  readonly showVAT: boolean;
  readonly showPrices: boolean;
  readonly hasSelectionColumn: boolean;
  readonly sparePart: PriceableSparePart;
  readonly packageDeal: PriceablePackageDeal;
  readonly hasMultiplePurchaseOrders: boolean;
  readonly canTogglePackageDealStatus: boolean;
  readonly notReferencedSparePartIdsFromAvailablePackageDeals?: readonly string[];
}

function SingleSparePartRowComponent({
  showVAT,
  sparePart,
  packageDeal,
  showPrices,
  hasSelectionColumn,
  hasMultiplePurchaseOrders,
  canTogglePackageDealStatus,
  notReferencedSparePartIdsFromAvailablePackageDeals = [],
}: SingleSparePartRowComponentProps): JSX.Element {
  const [t] = useTranslation('custom');

  const sparePartLabel = useMemo(
    () =>
      sparePart.managementType === 'fullyManagedByStimcar'
        ? packageDealHelpers.getSparePartDisplayedLabel(sparePart, packageDeal)
        : packageDealHelpers.getSparePartDisplayedLabelWithAsterix(sparePart, packageDeal),
    [packageDeal, sparePart]
  );
  const isNotReferencedButShouldBe = useMemo(
    () => notReferencedSparePartIdsFromAvailablePackageDeals.includes(sparePart.id),
    [notReferencedSparePartIdsFromAvailablePackageDeals, sparePart]
  );

  const isRowWithVAT = useMemo(
    () => packageDealHelpers.isPackageDealWithVAT(packageDeal),
    [packageDeal]
  );
  const priceWithoutVAT = useMemo(() => priceHelpers.getPriceWithoutVAT(sparePart), [sparePart]);
  const priceWithVAT = useMemo(() => priceHelpers.getPriceWithVAT(sparePart), [sparePart]);

  return (
    <React.Fragment key={sparePart.id}>
      <tr>
        <td />
        <td />
        <td>
          <SparePartLabelCell
            label={sparePartLabel}
            showNotReferencedIcon={isNotReferencedButShouldBe}
          />
        </td>
        <td className="has-text-right">{sparePart.quantity}</td>
        <td>
          <MultilineValueTableCellContent value={sparePart.commentForCustomer} />
        </td>
        {showPrices && (
          <>
            <td className="has-text-right">
              {t('documentGeneration.estimate.packageDealsInformations.price', {
                amount: priceWithoutVAT.toFixed(2),
              })}
            </td>
            {showVAT && (
              <>
                <td className="has-text-right">
                  {t('documentGeneration.estimate.packageDealsInformations.VAT', {
                    percentage: isRowWithVAT ? VATPercentage : 0,
                  })}
                </td>
                <td className="has-text-right">
                  {t('documentGeneration.estimate.packageDealsInformations.price', {
                    amount: isRowWithVAT ? priceWithVAT.toFixed(2) : priceWithoutVAT.toFixed(2),
                  })}
                </td>
              </>
            )}
          </>
        )}
        {hasSelectionColumn && (
          <>
            {canTogglePackageDealStatus && <td className="no-printing" />}
            {hasMultiplePurchaseOrders && <td className="no-printing" />}
          </>
        )}
      </tr>
    </React.Fragment>
  );
}
