import type {
  CarElement,
  CopyPackageDealDescMode,
  OperationDesc,
  PackageDealDesc,
  PackageDealVariableDefinition,
  SparePart,
  SparePartDesc,
} from '@stimcar/libs-base';
import type { BusinessObjectToFormData, WithFormValidationWarnings } from '@stimcar/libs-uikernel';
import type {
  ComponentWithMountTracking,
  FormWithValidationState,
  TableState,
} from '@stimcar/libs-uitoolkit';
import { EMPTY_PACKAGE_DEAL_DESC, MKTP_PKG_CATEGORY_NA } from '@stimcar/libs-base';
import { TABLE_EMPTY_STATE } from '@stimcar/libs-uitoolkit';
import type { ByCarElementsFilterState } from '../../carElement/ByCarElementsFilter.js';

export type PackageDealDescRawFormData = {
  readonly code: string;
  readonly label: string;
  readonly hint: string;
  readonly variableDescsString: string;
  readonly category: string;
  readonly marketplaceCategory: string;
  readonly tags: readonly string[];
  readonly priceExpression: string;
  readonly durationExpression: string;
  readonly database: string;
  readonly isSubcontractable: boolean;
  readonly overridablePrice: boolean;
  readonly ignoreVAT: boolean;
  readonly mecaCarElementIds: readonly string[];
  readonly exteCarElementIds: readonly string[];
  readonly inteCarElementIds: readonly string[];
  readonly bumpCarElementIds: readonly string[];
};

export type UpdateCarElementsModalState = {
  readonly isActive: boolean;
  readonly initialCarElementIds: readonly string[];
  readonly preSelectedCarElements: readonly string[];
  readonly preUnselectedCarElements: readonly string[];
};

export const EMPTY_UPDATE_CAR_ELEMENTS_MODAL_STATE: UpdateCarElementsModalState = {
  isActive: false,
  initialCarElementIds: [],
  preSelectedCarElements: [],
  preUnselectedCarElements: [],
};

export type PackageDealDescAdditionalFormData = {
  readonly operationDescs: readonly OperationDesc[];
  readonly variableDescs: Record<string, PackageDealVariableDefinition | null>;
};

export type PackageDealDescFormDataWithWarning = PackageDealDescAdditionalFormData &
  WithFormValidationWarnings<PackageDealDescRawFormData>;

export type SparePartDescFormData = BusinessObjectToFormData<Pick<SparePartDesc, 'id' | 'label'>>;

export const EMPTY_SPARE_PART_FORM_DATA: SparePartFormData = {
  id: '',
  label: '',
  editMode: false,
  deleted: false,
};

export type SparePartFormData = BusinessObjectToFormData<
  Pick<SparePart, 'id' | 'label'> & {
    editMode: boolean;
    deleted: boolean;
  }
>;

export type SparePartDescForm = WithFormValidationWarnings<SparePartDescFormData>;

export const EMPTY_SPARE_PART_DESC_FORM_DATA: SparePartDescFormData = {
  id: '',
  label: '',
};

export const EMPTY_SPARE_PART_DESC_FORM: SparePartDescForm = {
  ...EMPTY_SPARE_PART_DESC_FORM_DATA,
  warnings: {},
};

export type OperationDescFormData = BusinessObjectToFormData<
  Pick<OperationDesc, 'label' | 'standId' | 'workloadExpression'>
>;

export type OperationDescForm = WithFormValidationWarnings<OperationDescFormData>;

export const EMPTY_OPERATION_DESC_FORM: OperationDescForm = {
  workloadExpression: '',
  label: '',
  standId: '',
  warnings: {},
};

export const EDIT_SPARE_PART_DESC_MODAL_EMPTY_STATE: EditSparePartDescModalState = {
  active: false,
  formData: EMPTY_SPARE_PART_DESC_FORM,
  formSubmitClickedOnce: false,
  formSubmitted: false,
  sparePartDescs: [],
  initialSparePartDesc: undefined,
};

export type EditSparePartDescModalState = FormWithValidationState<SparePartDescFormData> & {
  readonly active: boolean;
  readonly initialSparePartDesc: SparePartDesc | undefined;
  readonly sparePartDescs: readonly SparePartDesc[];
};

export type EditOperationDescModalState = FormWithValidationState<OperationDescFormData> & {
  readonly active: boolean;
  readonly initialOperationDesc: OperationDesc | undefined;
};

export const EDIT_OPERATION_DESC_MODAL_EMPTY_STATE: EditOperationDescModalState = {
  active: false,
  initialOperationDesc: undefined,
  formData: EMPTY_OPERATION_DESC_FORM,
  formSubmitClickedOnce: false,
  formSubmitted: false,
};

export const EMPTY_PACKAGE_DEAL_DESC_FORM: PackageDealDescFormDataWithWarning = {
  code: '',
  label: '',
  database: '',
  variableDescs: {},
  variableDescsString: '',
  priceExpression: '',
  durationExpression: '',
  hint: '',
  mecaCarElementIds: [],
  exteCarElementIds: [],
  bumpCarElementIds: [],
  inteCarElementIds: [],
  isSubcontractable: false,
  category: 'AUTRE',
  marketplaceCategory: MKTP_PKG_CATEGORY_NA,
  tags: [],
  overridablePrice: false,
  ignoreVAT: false,
  operationDescs: [],
  warnings: {},
};

type DurationsFormData = {
  readonly duration: string;
};
export type DurationsForm = WithFormValidationWarnings<DurationsFormData>;

export type EditDurationsModalState = FormWithValidationState<DurationsFormData> & {
  readonly active: boolean;
  readonly durations: readonly number[];
  readonly initialDurations: readonly number[];
};

export const EDIT_DURATIONS_MODAL_EMPTY_STATE: EditDurationsModalState = {
  active: false,
  durations: [],
  initialDurations: [],
  formData: {
    duration: '',
    warnings: {},
  },
  formSubmitClickedOnce: false,
  formSubmitted: false,
};

export type EditPackageDealDescModalState =
  FormWithValidationState<PackageDealDescFormDataWithWarning> & {
    readonly active: boolean;
    readonly selectedSparePartDescId: string;
    readonly selectedOdID: string;
    readonly initialPackageDealDesc: PackageDealDesc | undefined;
    readonly availableTags: readonly string[];
    readonly carElements: readonly CarElement[];
    readonly editSparePartDescModalState: EditSparePartDescModalState;
    readonly editOperationDescModalState: EditOperationDescModalState;
    readonly editDurationsModal: EditDurationsModalState;
    readonly updateMecaCarElementsModalState: UpdateCarElementsModalState;
    readonly updateInteCarElementsModalState: UpdateCarElementsModalState;
    readonly updateExteCarElementsModalState: UpdateCarElementsModalState;
    readonly updateBumpCarElementsModalState: UpdateCarElementsModalState;
  };

export const EMPTY_EDIT_PACKAGE_DEAL_DESC_MODAL_STATE: EditPackageDealDescModalState = {
  active: false,
  formSubmitClickedOnce: false,
  formSubmitted: false,
  formWarning: undefined,
  formData: EMPTY_PACKAGE_DEAL_DESC_FORM,
  carElements: [],
  availableTags: [],
  editSparePartDescModalState: EDIT_SPARE_PART_DESC_MODAL_EMPTY_STATE,
  editOperationDescModalState: EDIT_OPERATION_DESC_MODAL_EMPTY_STATE,
  editDurationsModal: EDIT_DURATIONS_MODAL_EMPTY_STATE,
  initialPackageDealDesc: undefined,
  selectedSparePartDescId: '',
  selectedOdID: '',
  updateBumpCarElementsModalState: EMPTY_UPDATE_CAR_ELEMENTS_MODAL_STATE,
  updateExteCarElementsModalState: EMPTY_UPDATE_CAR_ELEMENTS_MODAL_STATE,
  updateInteCarElementsModalState: EMPTY_UPDATE_CAR_ELEMENTS_MODAL_STATE,
  updateMecaCarElementsModalState: EMPTY_UPDATE_CAR_ELEMENTS_MODAL_STATE,
};

export type DeletePackageDealDescModalState = {
  readonly active: boolean;
  readonly packageDealId: string;
};

export const EMPTY_DELETE_PACKAGE_DEAL_DESC_MODEL_STATE: DeletePackageDealDescModalState = {
  active: false,
  packageDealId: '',
};

export type CopyPackageDealDescFormData = {
  readonly mode: CopyPackageDealDescMode;
  readonly selectedSites: readonly string[];
  readonly selectedPackageDealDatabases: readonly string[];
};

export type CopyPackageDealDescReportItem = {
  readonly entityId: string;
  readonly type: 'added' | 'unchanged' | 'updated';
  // DeepPartial is not compliant with State but we only need to print it
  // so we don't need the precise types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly payload?: any;
};

export type CopyPackageDealDescModalState = FormWithValidationState<CopyPackageDealDescFormData> & {
  readonly active: boolean;
  readonly packageDealDesc: PackageDealDesc;
  readonly sites: readonly string[];
  readonly packageDealDatabases: readonly string[];
  readonly report: Record<string /* companyId/siteId/database */, CopyPackageDealDescReportItem>;
};

export const EMPTY_COPY_PACKAGE_DEAL_DESC_MODEL_STATE: CopyPackageDealDescModalState = {
  active: false,
  packageDealDesc: EMPTY_PACKAGE_DEAL_DESC,
  packageDealDatabases: [],
  sites: [],
  formData: {
    mode: 'sameDatabaseOnOtherSites',
    warnings: {},
    selectedPackageDealDatabases: [],
    selectedSites: [],
  },
  formSubmitClickedOnce: false,
  formSubmitted: false,
  report: {},
  formWarning: undefined,
};

export type AdminPackageDealDescsState = TableState<PackageDealDesc> &
  ComponentWithMountTracking & {
    readonly searchFilter: ByCarElementsFilterState;
    readonly editPackageDealDescModal: EditPackageDealDescModalState;
    readonly copyPackageDealDescModal: CopyPackageDealDescModalState;
    readonly deleteModal: DeletePackageDealDescModalState;
    readonly carElements: readonly CarElement[];
    readonly unfoldedPackageDealsId: readonly string[];
    readonly selectedPackageDealDatabase: string;
  };

export const EMPTY_ADMIN_PACKAGE_DEAL_DESCS_STATE: AdminPackageDealDescsState = {
  ...TABLE_EMPTY_STATE,
  componentIsMounted: false,
  searchFilter: {
    category: '',
    shapeId: '',
  },
  selectedPackageDealDatabase: '',
  carElements: [],
  deleteModal: EMPTY_DELETE_PACKAGE_DEAL_DESC_MODEL_STATE,
  editPackageDealDescModal: EMPTY_EDIT_PACKAGE_DEAL_DESC_MODAL_STATE,
  copyPackageDealDescModal: EMPTY_COPY_PACKAGE_DEAL_DESC_MODEL_STATE,
  unfoldedPackageDealsId: [],
};
