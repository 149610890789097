import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { AttachmentFolder, Kanban, StorageCategories } from '@stimcar/libs-base';
import type { GlobalStoreStateSelector, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { TabElementDef } from '@stimcar/libs-uitoolkit';
import {
  CoreBackendRoutes,
  KANBAN_ATTRIBUTES,
  kanbanHelpers,
  MARKETPLACE_PHOTO_ATTACHMENT_FOLDER,
  MARKETPLACE_TEXT_ATTACHMENT_FOLDER,
  MARKETPLACE_TEXT_ATTACHMENTS_FOLDER_ID,
  URL_LIST_ELEMENTS_SEPARATOR,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState, useRecordItemSelector } from '@stimcar/libs-uikernel';
import {
  AttachmentsGallery,
  AttachmentsGalleryDialog,
  Button,
  DisplayContentOrPlaceholder,
  Tabs,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { loadAttachmentsGalleryAction } from '../../utils/attachmentGalleryActions.js';
import { useComputeAttachmentUrl } from '../../utils/useComputeAttachmentUrl.js';
import type { MarketplaceDetailsState } from './typings/store.js';
import { MarketplaceAd } from './MarketplaceAd.js';
import { MarketplaceEditAdModalDialog } from './MarketplaceEditAdModalDialog.js';
import { MarketplaceOverview } from './MarketplaceOverview.js';
import { MarketplacePicture } from './MarketplacePicture.js';
import { MARKETPLACE_DETAILS_EMPTY_STATE } from './typings/store.js';

const MARKETPLACE_PHOTO_FOLDERS: readonly AttachmentFolder[] = [
  MARKETPLACE_PHOTO_ATTACHMENT_FOLDER,
];
const MARKETPLACE_TEXT_FOLDERS: readonly AttachmentFolder[] = [MARKETPLACE_TEXT_ATTACHMENT_FOLDER];

export interface MarketplaceDetailsProps {
  readonly $: StoreStateSelector<Store, MarketplaceDetailsState>;
  readonly $gs: GlobalStoreStateSelector<Store>;
  readonly isEditable: boolean;
  readonly $selectedKanban: StoreStateSelector<Store, Kanban>;
}

export function MarketplaceDetails({
  $,
  $gs,
  isEditable,
  $selectedKanban,
}: MarketplaceDetailsProps): JSX.Element {
  const [t] = useTranslation('details');
  const isOnline = useGetState($gs.$session.$isOnline);
  const computeAttachmentUrl = useComputeAttachmentUrl($gs);
  const loadAttachmentsActionCallback = useActionCallback(
    async (
      { actionDispatch },
      category: StorageCategories,
      objectId: string,
      folders: readonly string[],
      reloadElements?: boolean
    ) => {
      await actionDispatch.exec(
        loadAttachmentsGalleryAction,
        CoreBackendRoutes.ATTACHMENT_FOLDER(
          category,
          objectId,
          folders.join(URL_LIST_ELEMENTS_SEPARATOR)
        ),
        reloadElements
      );
      actionDispatch.setProperty('loadingStatus', undefined);
    },
    [],
    $.$attachmentGalleryDialogModal
  );

  const kanbanId = useGetState($selectedKanban.$id);

  const onSelectAttachmentActionCallback = useActionCallback(
    async function onSelectAttachmentAction({ actionDispatch, getState, kanbanRepository }) {
      const filenameAttributeKey = getState().editedPictureAttribute!;
      const galleryDispatch = actionDispatch.scopeProperty('attachmentGalleryDialogModal');
      const [, filename] = nonnull(getState().attachmentGalleryDialogModal.selectedPaths)[0].split(
        '/'
      );
      await kanbanRepository.updateEntityFromPayload({
        entityId: kanbanId,
        payload: {
          attributes: {
            [filenameAttributeKey]: filename,
          },
        },
      });
      // Close dialog & empty selected paths
      galleryDispatch.setProperty('active', false);
      galleryDispatch.setProperty('selectedPaths', undefined);
    },
    [kanbanId],
    $
  );

  const tabs = useMemo((): Record<string, string | TabElementDef> => {
    return {
      overview: {
        label: t('tabs.marketplace.overview.title'),
        icon: { id: 'file-invoice-dollar' },
      },
      preview: {
        label: t('tabs.marketplace.previewTitle'),
        icon: { id: 'globe' },
      },
      photoAttachmentsGallery: {
        label: t('tabs.marketplace.photoAttachmentsGallery'),
        icon: { id: 'image' },
      },
      textAttachmentsGallery: {
        label: t('tabs.marketplace.textAttachmentsGallery'),
        icon: { id: 'brands/markdown' },
      },
    };
  }, [t]);

  const selectedTab = useGetState($.$selectedTab);

  const selectedKanban = useGetState($selectedKanban);
  const brand = useGetState($selectedKanban.$infos.$brand);
  const color = useGetState($selectedKanban.$infos.$color);
  const dateOfRegistration = useGetState($selectedKanban.$infos.$dateOfRegistration);
  const mileage = useGetState($selectedKanban.$infos.$mileage);
  const model = useGetState($selectedKanban.$infos.$model);
  const motor = useGetState($selectedKanban.$infos.$motor);

  const loadAdTextActionCallback = useActionCallback(
    async function loadAdTextAction({ actionDispatch, httpClient }) {
      const textAttachmentFilename =
        selectedKanban?.attributes[KANBAN_ATTRIBUTES.MKTP_TEXT_ATTACHMENT];
      if (!isTruthyAndNotEmpty(textAttachmentFilename as string | undefined)) {
        actionDispatch.setProperty('markdownText', MARKETPLACE_DETAILS_EMPTY_STATE.markdownText);
      } else {
        const textAttachmentResponse = await httpClient.httpGet(
          CoreBackendRoutes.ATTACHMENT(
            'kanban',
            kanbanId,
            MARKETPLACE_TEXT_ATTACHMENTS_FOLDER_ID,
            String(textAttachmentFilename)
          )
        );
        actionDispatch.setProperty('markdownText', await textAttachmentResponse.text());
      }
    },
    [selectedKanban?.attributes, kanbanId],
    $
  );

  const clearAdTextActionCallback = useActionCallback(
    function clearAdTextAction({ actionDispatch }) {
      actionDispatch.setValue(MARKETPLACE_DETAILS_EMPTY_STATE);
    },
    [],
    $
  );

  // Lazily load the ad text
  useEffect((): (() => void) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadAdTextActionCallback();
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    return clearAdTextActionCallback;
  }, [clearAdTextActionCallback, loadAdTextActionCallback]);

  const openEditAdModalDialogActionCallback = useActionCallback(
    async function openEditAdModalDialogAction({ actionDispatch, getState }) {
      // Refresh loaded markdown
      await actionDispatch.execCallback(loadAdTextActionCallback);
      // Init state
      actionDispatch.applyPayload({
        editAdDialog: {
          active: true,
          formData: {
            markdownText: getState().markdownText,
            brand,
            color,
            dateOfRegistration: dateOfRegistration === null ? 0 : dateOfRegistration,
            mileage: String(mileage),
            model,
            motor,
            ownerSellingPrice: String(
              selectedKanban?.attributes[KANBAN_ATTRIBUTES.MKTP_OWNER_SELLING_PRICE]
            ),
            publicSellingPrice: String(
              selectedKanban?.attributes[KANBAN_ATTRIBUTES.MKTP_PUBLIC_SELLING_PRICE]
            ),
            gearbox: String(selectedKanban?.attributes[KANBAN_ATTRIBUTES.MKTP_GEARBOX]),
            fuel: String(selectedKanban?.attributes[KANBAN_ATTRIBUTES.MKTP_FUEL]),
            marketplaceProcess: String(
              selectedKanban?.attributes[KANBAN_ATTRIBUTES.MKTP_PROCESS] ?? ''
            ),
          },
        },
      });
    },
    [
      selectedKanban?.attributes,
      brand,
      color,
      dateOfRegistration,
      loadAdTextActionCallback,
      mileage,
      model,
      motor,
    ],
    $
  );

  const $publicSellingPrice = useRecordItemSelector(
    $selectedKanban.$attributes,
    KANBAN_ATTRIBUTES.MKTP_PUBLIC_SELLING_PRICE
  ) as StoreStateSelector<Store, string>;

  const isMarketplaceKanban = useMemo(
    () => isTruthy(selectedKanban) && kanbanHelpers.isMarketplaceKanban(selectedKanban),
    [selectedKanban]
  );

  return (
    <DisplayContentOrPlaceholder
      displayCondition={isMarketplaceKanban}
      placeholder={t('kanbanAttributes.emptyPlaceholder')}
      isScrollable
    >
      <div className="container is-fluid mt-3">
        <div className="container is-max-widescreen" style={{ minHeight: 400 }}>
          <Tabs labels={tabs} $selectedTab={$.$selectedTab} isCentered />
          {selectedTab === 'overview' && (
            <MarketplaceOverview
              $={$.$marketplaceOverview}
              $gs={$gs}
              $selectedKanban={$selectedKanban}
            />
          )}
          {selectedTab === 'preview' && (
            <>
              <div className="fixed-grid has-12-cols">
                <div className="grid">
                  <div className="cell is-row-span-2 is-col-span-6">
                    <MarketplacePicture
                      $={$}
                      $gs={$gs}
                      filenameAttributeKey={KANBAN_ATTRIBUTES.MKTP_IDENTITY_PICTURE}
                      isEditable={isEditable}
                      $selectedKanban={$selectedKanban}
                    />
                  </div>
                  <div className="cell is-col-span-3">
                    <MarketplacePicture
                      $={$}
                      $gs={$gs}
                      filenameAttributeKey={KANBAN_ATTRIBUTES.MKTP_DETAIL_PICTURE(1)}
                      isEditable={isEditable}
                      $selectedKanban={$selectedKanban}
                    />
                  </div>
                  <div className="cell is-col-span-3">
                    <MarketplacePicture
                      $={$}
                      $gs={$gs}
                      filenameAttributeKey={KANBAN_ATTRIBUTES.MKTP_DETAIL_PICTURE(2)}
                      isEditable={isEditable}
                      $selectedKanban={$selectedKanban}
                    />
                  </div>
                  <div className="cell is-col-span-3">
                    <MarketplacePicture
                      $={$}
                      $gs={$gs}
                      filenameAttributeKey={KANBAN_ATTRIBUTES.MKTP_DETAIL_PICTURE(3)}
                      isEditable={isEditable}
                      $selectedKanban={$selectedKanban}
                    />
                  </div>
                  <div className="cell is-col-span-3">
                    <MarketplacePicture
                      $={$}
                      $gs={$gs}
                      filenameAttributeKey={KANBAN_ATTRIBUTES.MKTP_DETAIL_PICTURE(4)}
                      isEditable={isEditable}
                      $selectedKanban={$selectedKanban}
                    />
                  </div>
                </div>
              </div>
              <div>
                {isEditable && (
                  <Button
                    iconId="edit"
                    label={t('tabs.marketplace.editText')}
                    onClick={openEditAdModalDialogActionCallback}
                    additionalClass="is-primary"
                    additionalStyle={{ float: 'right' }}
                    disabled={!isOnline}
                  />
                )}
                <MarketplaceAd
                  $gs={$gs}
                  $markdownText={$.$markdownText}
                  $brand={$selectedKanban.$infos.$brand}
                  $color={$selectedKanban.$infos.$color}
                  $dateOfRegistration={$selectedKanban.$infos.$dateOfRegistration}
                  $mileage={$selectedKanban.$infos.$mileage}
                  $model={$selectedKanban.$infos.$model}
                  $motor={$selectedKanban.$infos.$motor}
                  $publicSellingPrice={$publicSellingPrice}
                />
                <MarketplaceEditAdModalDialog
                  $gs={$gs}
                  $={$.$editAdDialog}
                  kanbanId={kanbanId}
                  onAdChangedActionCallback={loadAdTextActionCallback}
                />
              </div>
              <AttachmentsGalleryDialog
                category="kanban"
                objectId={kanbanId}
                folders={MARKETPLACE_PHOTO_FOLDERS}
                $={$.$attachmentGalleryDialogModal}
                $window={$gs.$window}
                loadAttachmentsActionCallback={loadAttachmentsActionCallback}
                onOkClicked={onSelectAttachmentActionCallback}
                computeAttachmentUrl={computeAttachmentUrl}
                $imageModal={$gs.$imageModal}
                isOnline={isOnline}
                selectMode="one"
                onClickOnThumbnailBehavior="select"
              />
            </>
          )}
          {selectedTab === 'photoAttachmentsGallery' && (
            <AttachmentsGallery
              category="kanban"
              objectId={kanbanId}
              folders={MARKETPLACE_PHOTO_FOLDERS}
              $={$.$attachmentGalleryDialogModal}
              $window={$gs.$window}
              loadAttachmentsActionCallback={loadAttachmentsActionCallback}
              computeAttachmentUrl={computeAttachmentUrl}
              $imageModal={$gs.$imageModal}
              isOnline={isOnline}
            />
          )}
          {selectedTab === 'textAttachmentsGallery' && (
            <AttachmentsGallery
              category="kanban"
              objectId={kanbanId}
              folders={MARKETPLACE_TEXT_FOLDERS}
              $={$.$attachmentGalleryDialogModal}
              $window={$gs.$window}
              loadAttachmentsActionCallback={loadAttachmentsActionCallback}
              computeAttachmentUrl={computeAttachmentUrl}
              $imageModal={$gs.$imageModal}
              isOnline={isOnline}
            />
          )}
        </div>
      </div>
    </DisplayContentOrPlaceholder>
  );
}
