import type { MarketplaceStandardExternalMainPicture } from '@stimcar/libs-kernel';
import { MARKETPLACE_MAIN_EXTERNAL_STANDARD_PICTURES } from '@stimcar/libs-kernel';

export function getExternalStandardPicIds() {
  const picturesIds = Object.entries(MARKETPLACE_MAIN_EXTERNAL_STANDARD_PICTURES)
    .sort(([, { index: index1 }], [, { index: index2 }]) => index1 - index2)
    .map(([, { id }]) => id) as readonly MarketplaceStandardExternalMainPicture[];
  return picturesIds;
}

export function getPictureIconPath(id: string): string {
  return `img/car-${id}`;
}

export function getDefectPictureName(defectId: string): string {
  return `defect-${defectId}.webp`;
}
