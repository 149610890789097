import React from 'react';
import { useTranslation } from 'react-i18next';
import type {
  ActionCallbackFromFunction,
  ActionContext,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { InputFormField, ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../state/typings/store.js';
import type { UpdateRefurbishingOptionLabelDialogState } from '../../../typings/store.js';
import {
  EMPTY_UPDATE_REFURBISHING_OPTION_LABEL_DIALOG_STATE,
  EMPTY_UPDATE_REFURBISHING_OPTION_LABEL_FORM,
} from '../../../typings/store.js';

export function openUpdateRefurbishingOptionLabelModalAction(
  { actionDispatch }: ActionContext<Store, UpdateRefurbishingOptionLabelDialogState>,
  refurbishingOptionId: string,
  label: string
): void {
  actionDispatch.setValue({
    ...EMPTY_UPDATE_REFURBISHING_OPTION_LABEL_DIALOG_STATE,
    refurbishingOptionId,
    formData: {
      ...EMPTY_UPDATE_REFURBISHING_OPTION_LABEL_FORM,
      label,
    },
    active: true,
  });
}

type UpdateRefurbishingOptionLabelModalProps = {
  readonly $: StoreStateSelector<Store, UpdateRefurbishingOptionLabelDialogState>;
  readonly onSubmitCallback: ActionCallbackFromFunction<
    Store,
    (refurbishingOptionId: string, newLabel: string) => void
  >;
};

export function UpdateRefurbishingOptionLabelModal({
  $,
  onSubmitCallback,
}: UpdateRefurbishingOptionLabelModalProps): JSX.Element {
  const [tGlobal] = useTranslation('');
  const [t] = useTranslation('operators', {
    keyPrefix: 'defects.addOrUpdateDefect.updateRefurbishingOptionLabel',
  });

  const submitFormActionCallback = useActionCallback(
    async ({
      actionDispatch,
      getState,
    }: ActionContext<Store, UpdateRefurbishingOptionLabelDialogState>): Promise<void> => {
      const { refurbishingOptionId, formData } = getState();
      const { label } = formData;

      await actionDispatch.execCallback(onSubmitCallback, refurbishingOptionId, label);

      actionDispatch.applyPayload({
        active: false,
      });
    },
    [onSubmitCallback],
    $
  );

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    UpdateRefurbishingOptionLabelDialogState
  >({
    $,
    mandatoryFields: ['label'],
    checkFieldContentActions: undefined,
    checkFormConsistencyAction: undefined,
    submitValidDataAction: submitFormActionCallback,
    t: tGlobal,
  });

  const formWarning = useGetState($.$formWarning);

  return (
    <ModalCardDialog
      title={t('title')}
      $active={$.$active}
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <InputFormField
        label={t('label')}
        placeholder={t('label')}
        $={$formDataWithChangeTrigger.$label}
        horizontal
      />
    </ModalCardDialog>
  );
}
