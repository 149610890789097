import React from 'react';
import { useTranslation } from 'react-i18next';
import type { DeepPartial, Defect } from '@stimcar/libs-kernel';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import { nonDeleted } from '@stimcar/libs-base';
import { useActionCallback } from '@stimcar/libs-uikernel';
import { ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../state/typings/store.js';
import type { DefectsTabState, DeleteDefectDialogState } from '../../../typings/store.js';
import { EMPTY_DELETE_DEFECT_DIALOG_STATE } from '../../../typings/store.js';

export function openDeleteDefectModalAction(
  { actionDispatch }: ActionContext<Store, DeleteDefectDialogState>,
  defectId: string
): void {
  actionDispatch.setValue({
    ...EMPTY_DELETE_DEFECT_DIALOG_STATE,
    defectId,
    active: true,
  });
}

function deleteDefectAction({
  getState,
  actionDispatch,
}: ActionContext<Store, DefectsTabState>): void {
  const { deleteDefectDialogState, defects } = getState();
  const { defectId } = deleteDefectDialogState;
  // Remove defect from defects array
  // and recompute index for other defects

  // We take into account all non deleted defects that are not the current one,
  // sort them by the current index, and recalculate all indices
  const payloadForDeletion: DeepPartial<Defect> = { id: defectId, deleted: true };

  const payloadsForIndexRecomputation: readonly DeepPartial<Defect>[] = defects
    .filter(nonDeleted)
    .filter(({ id }) => id !== defectId)
    .sort(({ index: index1 }, { index: index2 }) => index1 - index2)
    .map(({ id }, index) => {
      return {
        id,
        index: index + 1,
      };
    });

  const fullPayload = [...payloadsForIndexRecomputation, payloadForDeletion];
  actionDispatch.scopeProperty('defects').applyPayload(fullPayload);

  // Then close dialog
  actionDispatch
    .scopeProperty('deleteDefectDialogState')
    .setValue(EMPTY_DELETE_DEFECT_DIALOG_STATE);
}

type DeleteDefectModalProps = {
  readonly $: StoreStateSelector<Store, DefectsTabState>;
};

export function DeleteDefectModal({ $ }: DeleteDefectModalProps): JSX.Element {
  const [t] = useTranslation('operators', { keyPrefix: 'defects.deleteDefect' });

  const deleteDefectActionCallback = useActionCallback(deleteDefectAction, [], $);

  return (
    <ModalCardDialog
      title={t('title')}
      $active={$.$deleteDefectDialogState.$active}
      okLabel={t('okButtonLabel')}
      onOkClicked={deleteDefectActionCallback}
    >
      <p>{t('confirmDeletionMsg')}</p>
    </ModalCardDialog>
  );
}
