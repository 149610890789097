import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { DeepPartial, Defect } from '@stimcar/libs-kernel';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import { nonDeleted } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { FaIcon, TruncableTableTd } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type { DefectsTabState } from '../../typings/store.js';
import { openUpdateDefectDialogAction } from './modal/AddOrUpdateDefectModal.js';
import { openDeleteDefectModalAction } from './modal/DeleteDefectModal.js';

function canMoveUp(defect: Defect): boolean {
  return defect.index > 1;
}

function canMoveDown(defect: Defect, defects: readonly Defect[]): boolean {
  return defect.index < defects.filter(nonDeleted).length;
}

function moveDefectAction(
  { actionDispatch, getState }: ActionContext<Store, readonly Defect[]>,
  defect: Defect,
  delta: 1 | -1
): void {
  const defects = getState().filter(nonDeleted);

  if (delta === 1 && !canMoveDown(defect, defects)) {
    // Defect is moving down, it cannot be already the last one
    return;
  }
  if (delta === -1 && !canMoveUp(defect)) {
    // Defect is moving down, it cannot be already the first one
    return;
  }

  const startingIndex = defect.index;
  const endingIndex = startingIndex + delta;
  const otherDefect = defects.find(({ index }) => index === endingIndex);

  if (isTruthy(otherDefect)) {
    const payload: readonly DeepPartial<Defect>[] = [
      {
        id: defect.id,
        index: endingIndex,
      },
      {
        id: otherDefect.id,
        index: startingIndex,
      },
    ];
    actionDispatch.applyPayload(payload);
  }
}

interface DefectsTableItemComponentProps {
  readonly $: StoreStateSelector<Store, DefectsTabState>;
  readonly defect: Defect;
  readonly isEditable: boolean;
}

export function DefectsTableItemComponent({
  $,
  defect,
  isEditable,
}: DefectsTableItemComponentProps): JSX.Element {
  const { id, index, type, label, refurbishingOptions } = defect;
  const [t] = useTranslation('operators', { keyPrefix: 'defects.table' });
  const [tDefectsTypes] = useTranslation('operators', { keyPrefix: 'defects.types' });

  const defects = useGetState($.$defects).filter(nonDeleted);

  const openUpdateDefectDialogCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openUpdateDefectDialogAction, defect);
    },
    [defect],
    $.$addOrUpdateDefectDialogState
  );

  const openDeleteDefectDialogCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openDeleteDefectModalAction, id);
    },
    [id],
    $.$deleteDefectDialogState
  );

  const moveUpDefectCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(moveDefectAction, defect, -1);
    },
    [defect],
    $.$defects
  );

  const moveDownDefectCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(moveDefectAction, defect, 1);
    },
    [defect],
    $.$defects
  );

  const optionsLabel = useMemo(() => {
    return refurbishingOptions.length > 0
      ? refurbishingOptions.map(({ label }) => label).join(', ')
      : t('noOptions');
  }, [refurbishingOptions, t]);

  return (
    <tr>
      <TruncableTableTd className="has-text-centered">{index}</TruncableTableTd>
      <TruncableTableTd>{tDefectsTypes(type)}</TruncableTableTd>
      <TruncableTableTd>{label}</TruncableTableTd>
      <TruncableTableTd>{optionsLabel}</TruncableTableTd>
      <td aria-label="actions" className="has-text-centered px-0" style={{ whiteSpace: 'nowrap' }}>
        <button
          key="update"
          aria-label="update"
          type="button"
          className="button is-small is-transparent"
          title={t('actions.update')}
          onClick={openUpdateDefectDialogCallback}
          disabled={!isEditable}
        >
          <FaIcon id="edit" tooltip={t('actions.update')} />
        </button>
        <button
          key="delete"
          aria-label="delete"
          type="button"
          className="button is-small is-transparent"
          title={t('actions.delete')}
          onClick={openDeleteDefectDialogCallback}
          disabled={!isEditable}
        >
          <FaIcon id="trash" tooltip={t('actions.delete')} />
        </button>
        <button
          key="up"
          aria-label="up"
          type="button"
          className="button is-small is-transparent"
          title={t('actions.up')}
          onClick={moveUpDefectCallback}
          disabled={!canMoveUp(defect) || !isEditable}
        >
          <FaIcon id="square-caret-up" tooltip={t('actions.up')} />
        </button>
        <button
          key="down"
          aria-label="down"
          type="button"
          className="button is-small is-transparent"
          title={t('actions.down')}
          onClick={moveDownDefectCallback}
          disabled={!canMoveDown(defect, defects) || !isEditable}
        >
          <FaIcon id="square-caret-down" tooltip={t('actions.down')} />
        </button>
      </td>
    </tr>
  );
}
