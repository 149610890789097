import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Defect } from '@stimcar/libs-kernel';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import { nonDeleted, sortingHelpers } from '@stimcar/libs-base';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Button, ScrollableTableComponent } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type { DefectsTabState } from '../../typings/store.js';
import { TableSortableHeaderComponent } from '../../../../lib/components/TableSortableHeaderComponent.js';
import { DefectsTableItemComponent } from './DefectsTableItemComponent.js';
import { openAddDefectDialogAction } from './modal/AddOrUpdateDefectModal.js';

interface DefectsTableComponentProps {
  readonly $: StoreStateSelector<Store, DefectsTabState>;
  readonly isEditable: boolean;
}

export function DefectsTableComponent({ $, isEditable }: DefectsTableComponentProps): JSX.Element {
  const [t] = useTranslation('operators', { keyPrefix: 'defects.table' });

  const openAddDefectDialogCallback = useActionCallback(
    openAddDefectDialogAction,
    [],
    $.$addOrUpdateDefectDialogState
  );

  const defects = useGetState($.$defects).filter(nonDeleted);

  const sortBy = useGetState($.$sort.$by);
  const sortDirection = useGetState($.$sort.$direction);

  const sortedDefects = useMemo(() => {
    let sortFunction: (c1: Defect, c2: Defect) => number;
    switch (sortBy) {
      case 'index':
        sortFunction = sortingHelpers.createSortByNumericField<Defect>(sortDirection, 'index');
        break;
      case 'type':
        sortFunction = sortingHelpers.createSortByStringField<Defect>(sortDirection, 'type');
        break;
      case 'label':
        sortFunction = sortingHelpers.createSortByStringField<Defect>(sortDirection, 'label');
        break;
      default:
        return defects;
    }
    return [...defects].sort(sortFunction);
  }, [defects, sortBy, sortDirection]);

  return (
    <ScrollableTableComponent
      tableClassName="table defects-table is-striped is-hoverable"
      height="300px"
    >
      <thead>
        <tr>
          <TableSortableHeaderComponent
            content={t('index')}
            isTruncable
            sortedField="index"
            $sort={$.$sort}
          />
          <TableSortableHeaderComponent
            content={t('type')}
            isTruncable
            sortedField="type"
            $sort={$.$sort}
            centerLabel={false}
          />
          <TableSortableHeaderComponent
            content={t('label')}
            isTruncable
            sortedField="label"
            $sort={$.$sort}
          />
          <th className="has-text-centered">{t('refurbishingOptions')}</th>
          <th aria-label="addDefect" className="has-text-centered">
            <Button
              iconId="plus"
              onClick={openAddDefectDialogCallback}
              size="small"
              tooltip={t('actions.add')}
              disabled={!isEditable}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {sortedDefects.map((defect) => (
          <DefectsTableItemComponent
            $={$}
            key={defect.id}
            defect={defect}
            isEditable={isEditable}
          />
        ))}
      </tbody>
    </ScrollableTableComponent>
  );
}
