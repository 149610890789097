import { t } from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  Attachment,
  AttachmentMetadata,
  PackageDeal,
  RepositoryEntityStatus,
} from '@stimcar/libs-base';
import type { DeepPartial, Defect, RefurbishingOption } from '@stimcar/libs-kernel';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps, CheckFormConsistencyAction } from '@stimcar/libs-uitoolkit';
import {
  CoreBackendRoutes,
  MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID,
  nonDeleted,
} from '@stimcar/libs-base';
import { DEFECT_TYPES, isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import {
  ConfirmAttachmentRemovalDialog,
  EMPTY_CONFIRM_ATTACHMENT_DIALOG_STATE,
  InputFormField,
  ModalCardDialog,
  ReactSelectFormField,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { PictureInputState } from '../../../../../lib/components/typings/store.js';
import type { Store } from '../../../../state/typings/store.js';
import type { AddOrUpdateDefectDialogState } from '../../../typings/store.js';
import { shouldAllowAttachmentDeletion } from '../../../../details/kanbanDetailsUtils.js';
import { appendRegisteredBrowserSessionToken } from '../../../../utils/security.js';
import { useComputeAttachmentUrl } from '../../../../utils/useComputeAttachmentUrl.js';
import {
  EMPTY_ADD_OR_UPDATE_DEFECT_DIALOG_STATE,
  EMPTY_ADD_OR_UPDATE_DEFECT_FORM,
  EMPTY_POSITION_ON_CAR,
} from '../../../typings/store.js';
import { getDefectPictureName, getExternalStandardPicIds } from '../defectUtils.js';
import { PositionOnCarSelector } from '../PositionOnCarSelector.js';
import { AddPackageDealToDefectModal } from './AddPackageDealToDefectModal.js';
import { DefectPictureSelector } from './DefectPictureSelector.js';
import { DeleteRefurbishingOptionModal } from './DeleteRefurbishingOptionModal.js';
import { PackageDealForDefectItem } from './PackageDealForDefectItem.js';
import { RefurbishingOptionItem } from './RefurbishingOptionItem.js';
import { UpdateRefurbishingOptionLabelModal } from './UpdateRefurbishingOptionLabelModal.js';

function getMissingDefectPictureWarningMessage(): string {
  return t('operators:defects.addOrUpdateDefect.form.error.invalidPicture');
}

/**
 * Check if a picture is present
 * @returns undefined if there is no problem else a string containing the warning message
 */
function checkPresenceOfDefectPicture(pictureInputState: PictureInputState): string | undefined {
  // Check that a picture has been selected to show the defect
  const { id, isSet } = pictureInputState;
  const isPictureValid = isTruthyAndNotEmpty(id) && isSet;
  if (!isPictureValid) {
    // Add warning to form
    return getMissingDefectPictureWarningMessage();
  }
  return undefined;
}

const checkFormConsistencyAction: CheckFormConsistencyAction<
  Store,
  AddOrUpdateDefectDialogState
> = ({ formState, t }): string | undefined => {
  const { formData } = formState;
  const { positionOnCar } = formData;

  // Check that position on car is valid
  const { pictureId, x, y } = positionOnCar;
  const isPositionOnCarValid = isTruthyAndNotEmpty(pictureId) && isTruthy(x) && isTruthy(y);
  if (!isPositionOnCarValid) {
    return t('operators:defects.addOrUpdateDefect.form.error.invalidPosition');
  }

  return undefined;
};

export function openAddDefectDialogAction({
  actionDispatch,
  httpClient,
}: ActionContext<Store, AddOrUpdateDefectDialogState>): void {
  const newDefectId = httpClient.getBrowserSequence().next();
  actionDispatch.setValue({
    ...EMPTY_ADD_OR_UPDATE_DEFECT_DIALOG_STATE,
    active: true,
    defectId: newDefectId,
    updateMode: false,
    selectedPictureId: getExternalStandardPicIds()[0],
    formData: {
      ...EMPTY_ADD_OR_UPDATE_DEFECT_FORM,
    },
    pictureInputState: {
      id: getDefectPictureName(newDefectId),
      isSet: false,
    },
  });
}

export function openUpdateDefectDialogAction(
  { actionDispatch }: ActionContext<Store, AddOrUpdateDefectDialogState>,
  { id, type, label, positionOnCar, refurbishingOptions }: Defect
): void {
  actionDispatch.setValue({
    ...EMPTY_ADD_OR_UPDATE_DEFECT_DIALOG_STATE,
    active: true,
    defectId: id,
    updateMode: true,
    selectedPictureId: positionOnCar?.pictureId ?? getExternalStandardPicIds()[0],
    refurbishingOptions,
    formData: {
      ...EMPTY_ADD_OR_UPDATE_DEFECT_FORM,
      type,
      label,
      positionOnCar: positionOnCar ?? EMPTY_POSITION_ON_CAR,
    },
    pictureInputState: {
      id: getDefectPictureName(id),
      isSet: true,
    },
  });
}

function addDefectToDefectsAction(
  { actionDispatch, getState }: ActionContext<Store, readonly Defect[]>,
  newDefect: Defect
): void {
  const nextIndex =
    getState()
      .filter(nonDeleted)
      .reduce((max, defect) => (defect.index > max ? defect.index : max), 0) + 1;
  actionDispatch.applyPayload([
    {
      ...newDefect,
      index: nextIndex,
    },
  ]);
}

interface AddOrUpdateDefectModalProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, AddOrUpdateDefectDialogState>;
  readonly $kanbanId: StoreStateSelector<Store, string>;
  readonly $kanbanStatus: StoreStateSelector<Store, RepositoryEntityStatus>;
  readonly $defects: StoreStateSelector<Store, readonly Defect[]>;
  readonly $packageDeals: StoreStateSelector<Store, readonly PackageDeal[]>;
}

export function AddOrUpdateDefectModal({
  $,
  $kanbanId,
  $kanbanStatus,
  $defects,
  $packageDeals,
  $gs,
}: AddOrUpdateDefectModalProps): JSX.Element {
  const [tGlobal] = useTranslation('');
  const [t] = useTranslation('operators', { keyPrefix: 'defects.addOrUpdateDefect' });
  const [tDefectsTypes] = useTranslation('operators', { keyPrefix: 'defects.types' });

  const updateMode = useGetState($.$updateMode);

  const { $selectedPictureId } = $;

  const addDefectToDefectsActionCallback = useActionCallback(
    addDefectToDefectsAction,
    [],
    $defects
  );

  const updateDefectIntoDefectsActionCallback = useActionCallback(
    (
      { actionDispatch }: ActionContext<Store, readonly Defect[]>,
      updatedDefectPayload: DeepPartial<Defect>
    ): void => {
      actionDispatch.applyPayload([updatedDefectPayload]);
    },
    [],
    $defects
  );

  const submitFormActionCallback = useActionCallback(
    async ({
      actionDispatch,
      getState,
    }: ActionContext<Store, AddOrUpdateDefectDialogState>): Promise<void> => {
      const { defectId, refurbishingOptions, formData } = getState();
      const { type, label, positionOnCar } = formData;
      const { pictureInputState } = getState();

      const defectPictureWarning = checkPresenceOfDefectPicture(pictureInputState);
      if (isTruthy(defectPictureWarning)) {
        // Set the warning in form and do nothing else
        actionDispatch.setProperty('formWarning', defectPictureWarning);
      } else {
        const newType = type !== '' ? type : DEFECT_TYPES[0];

        if (updateMode) {
          const defectPayload: DeepPartial<Defect> = {
            id: defectId,
            type: newType,
            label,
            picture: pictureInputState.id,
            positionOnCar,
            refurbishingOptions,
          };
          await actionDispatch.execCallback(updateDefectIntoDefectsActionCallback, defectPayload);
        } else {
          const defect: Defect = {
            id: defectId,
            type: newType,
            index: -1, // Index will be calculated when adding this defect to the list of defects
            label,
            picture: pictureInputState.id,
            positionOnCar,
            refurbishingOptions,
          };
          await actionDispatch.execCallback(addDefectToDefectsActionCallback, defect);
        }
        actionDispatch.setValue(EMPTY_ADD_OR_UPDATE_DEFECT_DIALOG_STATE);
      }
    },
    [addDefectToDefectsActionCallback, updateDefectIntoDefectsActionCallback, updateMode],
    $
  );

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    AddOrUpdateDefectDialogState
  >({
    $,
    mandatoryFields: ['type', 'label'],
    checkFieldContentActions: undefined,
    checkFormConsistencyAction,
    submitValidDataAction: submitFormActionCallback,
    t: tGlobal,
  });

  const defectLabels = useMemo(
    () => DEFECT_TYPES.map((type) => ({ value: type, label: tDefectsTypes(type) })),
    [tDefectsTypes]
  );

  const formWarning = useGetState($.$formWarning);

  const refurbishingOptions = useGetState($.$refurbishingOptions)
    .filter(nonDeleted)
    .sort(({ index: index1 }, { index: index2 }) => index1 - index2);
  const usedPackageDealsIds = refurbishingOptions.map(({ packageDealId }) => packageDealId);

  const packageDeals = useGetState($packageDeals)
    .filter(nonDeleted)
    .filter(({ carElement }) => isTruthy(carElement));

  const packageDealsNotYetUsed = packageDeals.filter(({ id }) => !usedPackageDealsIds.includes(id));

  const updateRefurbishingOptionLabelActionCallback = useActionCallback(
    (
      { actionDispatch }: ActionContext<Store, AddOrUpdateDefectDialogState>,
      refurbishingOptionId: string,
      newLabel: string
    ): void => {
      const updatedOption: DeepPartial<RefurbishingOption> = {
        id: refurbishingOptionId,
        label: newLabel,
      };
      actionDispatch.applyPayload({ refurbishingOptions: [updatedOption] });
    },
    [],
    $
  );

  const addRefurbishingOptionActionCallback = useActionCallback(
    (
      { actionDispatch, getState, httpClient }: ActionContext<Store, AddOrUpdateDefectDialogState>,
      packageDealId: string,
      label: string
    ): void => {
      const { refurbishingOptions } = getState();
      const newOption: RefurbishingOption = {
        id: httpClient.getBrowserSequence().next(),
        packageDealId,
        label,
        index: refurbishingOptions.length + 1,
      };
      actionDispatch.applyPayload({ refurbishingOptions: [newOption] });
    },
    [],
    $
  );

  const kanbanId = useGetState($kanbanId);
  const kanbanStatus = useGetState($kanbanStatus);
  const computeAttachmentUrl = useComputeAttachmentUrl($gs);

  const onRemoveDefectPictureActionCallback = useActionCallback(
    async ({ httpClient, getState, actionDispatch, getGlobalState }) => {
      const { name: filename } = getState().confirmAttachmentRemovalDialog;
      await httpClient.httpGet(
        appendRegisteredBrowserSessionToken(
          CoreBackendRoutes.ATTACHMENT(
            'kanban',
            kanbanId,
            MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID,
            filename
          ),
          nonnull(getGlobalState().session.infos).sessionToken
        ),
        'DELETE'
      );
      actionDispatch.scopeProperty('pictureInputState').setProperty('isSet', false);
      actionDispatch
        .scopeProperty('confirmAttachmentRemovalDialog')
        .setValue(EMPTY_CONFIRM_ATTACHMENT_DIALOG_STATE);
    },
    [kanbanId],
    $
  );

  const shouldRestrictAttachmentRemoval = useCallback(
    (attachment: Attachment, metadata: AttachmentMetadata | undefined): boolean => {
      return !shouldAllowAttachmentDeletion(kanbanStatus, attachment, metadata);
    },
    [kanbanStatus]
  );

  // Callback called when defect's picture has changed so we can try to re-submit the form
  const onDefectPictureChangedActionCallback = useActionCallback(
    ({ actionDispatch, getState }) => {
      const { formWarning } = getState();

      // We reset the form warning if it concerns the defect's picture
      if (formWarning === getMissingDefectPictureWarningMessage()) {
        actionDispatch.setProperty('formWarning', undefined);
        actionDispatch.setProperty('formSubmitted', false);
      }
    },
    [],
    $
  );

  const $pictureInputStateWithChangeTrigger = useSelectorWithChangeTrigger(
    $.$pictureInputState,
    onDefectPictureChangedActionCallback
  );

  return (
    <>
      <ModalCardDialog
        title={updateMode ? t('updateTitle') : t('addTitle')}
        $active={$.$active}
        okLabel={t('saveButton')}
        onOkClicked={onFormSubmit}
        warning={formWarning}
      >
        <div className="fixed-grid has-2-cols">
          <div className="grid is-gap-3">
            <div className="cell">
              <PositionOnCarSelector
                $selectedPictureId={$selectedPictureId}
                $positionOnCar={$formDataWithChangeTrigger.$positionOnCar}
                $kanbanId={$kanbanId}
                $gs={$gs}
              />
            </div>
            <div className="cell">
              <DefectPictureSelector
                $={$pictureInputStateWithChangeTrigger}
                $kanbanId={$kanbanId}
                placeholderPicturePath="/img/dot.gif"
                $confirmAttachmentRemovalDialog={$.$confirmAttachmentRemovalDialog}
                $gs={$gs}
              />
            </div>
          </div>
        </div>
        <ReactSelectFormField
          label={t('type')}
          $={$formDataWithChangeTrigger.$type}
          horizontal
          suggestions={defectLabels}
        />
        <InputFormField
          label={t('label')}
          placeholder={t('label')}
          $={$formDataWithChangeTrigger.$label}
          horizontal
        />
        <h5>{t('refurbishingOptions.title')}</h5>
        {refurbishingOptions.length <= 0 && <p>{t('refurbishingOptions.empty')}</p>}
        {refurbishingOptions.length > 0 && (
          <table className="table is-bordered is-fullwidth is-narrow is-striped">
            <thead>
              <tr>
                <th style={{ width: '1%' }}>{}</th>
                <th style={{ width: '35%' }}>{t('refurbishingOptions.label')}</th>
                <th style={{ width: '20%' }}>{t('refurbishingOptions.element')}</th>
                <th style={{ width: '35%' }}>{t('refurbishingOptions.packageDealLabel')}</th>
                <th style={{ width: '12%', whiteSpace: 'nowrap' }}>
                  {t('refurbishingOptions.price')}
                </th>
                <th style={{ width: '1%' }}>{}</th>
              </tr>
            </thead>
            <tbody>
              {refurbishingOptions.map((refurbishingOption): JSX.Element => {
                const packageDeal = nonnull(
                  packageDeals.find(({ id }) => refurbishingOption.packageDealId === id)
                );
                return (
                  <RefurbishingOptionItem
                    key={refurbishingOption.id}
                    refurbishingOption={refurbishingOption}
                    packageDeal={packageDeal}
                    $={$}
                  />
                );
              })}
            </tbody>
          </table>
        )}
        <h5>{t('availablePackageDeals.title')}</h5>
        {packageDealsNotYetUsed.length <= 0 && <p>{t('availablePackageDeals.empty')}</p>}
        {packageDealsNotYetUsed.length > 0 && (
          <table className="table is-bordered is-fullwidth is-narrow is-striped">
            <thead>
              <tr>
                <th style={{ width: '23%' }}>{t('availablePackageDeals.element')}</th>
                <th style={{ width: '62%' }}>{t('availablePackageDeals.label')}</th>
                <th style={{ width: '14%' }}>{t('availablePackageDeals.price')}</th>
                <th style={{ width: '1%' }}>{}</th>
              </tr>
            </thead>
            <tbody>
              {packageDealsNotYetUsed.map((packageDeal) => (
                <PackageDealForDefectItem
                  key={packageDeal.id}
                  packageDeal={packageDeal}
                  $={$.$addPackageDealToDefectDialogState}
                />
              ))}
            </tbody>
          </table>
        )}
      </ModalCardDialog>
      <UpdateRefurbishingOptionLabelModal
        $={$.$updateRefurbishingOptionLabelDialogState}
        onSubmitCallback={updateRefurbishingOptionLabelActionCallback}
      />
      <AddPackageDealToDefectModal
        $={$.$addPackageDealToDefectDialogState}
        onSubmitCallback={addRefurbishingOptionActionCallback}
      />
      <DeleteRefurbishingOptionModal $={$} />
      <ConfirmAttachmentRemovalDialog
        category="kanban"
        objectId={kanbanId}
        onOkClicked={onRemoveDefectPictureActionCallback}
        $={$.$confirmAttachmentRemovalDialog}
        computeAttachmentUrl={computeAttachmentUrl}
        okLabel={t('confirmAttachmentRemovalDialog.okLabel')}
        shouldRestrictRemove={shouldRestrictAttachmentRemoval}
      >
        <p>{t('confirmAttachmentRemovalDialog.message')}</p>
      </ConfirmAttachmentRemovalDialog>
    </>
  );
}
