import '@mdxeditor/editor/style.css';
import React, { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { FuelValues, GearboxValues, MarketplaceProcess } from '@stimcar/libs-kernel';
import type {
  ActionContext,
  NoArgActionCallback,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import type { SimpleOption } from '@stimcar/libs-uitoolkit';
import {
  AVAILABLE_BRANDS,
  CoreBackendRoutes,
  KANBAN_ATTRIBUTES,
  MARKETPLACE_TEXT_ATTACHMENTS_FOLDER_ID,
} from '@stimcar/libs-base';
import { applyPayload, computePayload, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useFormFieldWarning, useGetState } from '@stimcar/libs-uikernel';
import {
  CalendarFormField,
  FormField,
  InputFormField,
  ModalCardDialog,
  ReactSelectFormField,
  useFormWithValidation,
  WithWarning,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { LazyMarkdownEditor } from '../../../utils/mdxeditor/LazyMarkdownEditor.js';
import type { MarketplaceDetailsProps } from './MarketplaceDetails.js';
import type { MarketplaceEditAdDialogState, MarketplaceEditAdForm } from './typings/store.js';
import { MarketplaceAdHeader } from './MarketplaceAdHeader.js';

const mandatoryFields: readonly (keyof MarketplaceEditAdForm)[] = [
  'brand',
  'model',
  'mileage',
  'dateOfRegistration',
  'markdownText',
];

function toYYYYMMDDHHMMSS(date: Date): string {
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  const year = date.getFullYear();
  const hour = `0${date.getHours()}`.slice(-2);
  const min = `0${date.getMinutes()}`.slice(-2);
  const sec = `0${date.getSeconds()}`.slice(-2);
  return `${year}${month}${day}-${hour}${min}${sec}`;
}

interface Props extends Pick<MarketplaceDetailsProps, '$gs'> {
  readonly $: StoreStateSelector<Store, MarketplaceEditAdDialogState>;
  readonly kanbanId: string;
  readonly onAdChangedActionCallback?: NoArgActionCallback<Store>;
}

export function MarketplaceEditAdModalDialog({
  $,
  $gs,
  kanbanId,
  onAdChangedActionCallback,
}: Props): JSX.Element {
  const [t] = useTranslation('details');

  const submitValidDataActionCallback = useActionCallback(
    async function submitValidDataAction({
      getState,
      actionDispatch,
      kanbanRepository,
      httpClient,
    }: ActionContext<Store, MarketplaceEditAdDialogState>) {
      actionDispatch.setProperty('active', false);
      const {
        brand,
        color,
        dateOfRegistration,
        markdownText,
        mileage,
        model,
        motor,
        gearbox,
        fuel,
        marketplaceProcess,
      } = getState().formData;
      const kanban = await kanbanRepository.getEntity(kanbanId);
      let newKanban = applyPayload(kanban, {
        infos: {
          brand,
          color,
          model,
          motor,
          dateOfRegistration,
          mileage: Number.parseInt(mileage, 10),
        },
        attributes: {
          [KANBAN_ATTRIBUTES.MKTP_GEARBOX]: gearbox,
          [KANBAN_ATTRIBUTES.MKTP_FUEL]: fuel,
          [KANBAN_ATTRIBUTES.MKTP_PROCESS]: marketplaceProcess,
        },
      });
      if (markdownText !== getState().initialMarkdownText) {
        const { login } = nonnull(await httpClient.getAuthenticatedUser());
        const filename = `${toYYYYMMDDHHMMSS(new Date())}-${login}.md`;
        await httpClient.httpPostAsFile(
          CoreBackendRoutes.ATTACHMENT_FOLDER(
            'kanban',
            kanbanId,
            MARKETPLACE_TEXT_ATTACHMENTS_FOLDER_ID
          ),
          {
            [filename]: new Blob([markdownText], {
              type: 'text/markdown',
            }),
          },
          'PUT'
        );
        // Update the kanban
        newKanban = applyPayload(newKanban, {
          attributes: {
            [KANBAN_ATTRIBUTES.MKTP_TEXT_ATTACHMENT]: filename,
          },
        });
      }

      // Update the kanban in the repository if needed
      const payload = computePayload(kanban, newKanban);
      if (payload !== undefined) {
        await kanbanRepository.updateEntityFromPayload({ entityId: kanbanId, payload });
      }
      actionDispatch.setProperty('active', false);
      if (onAdChangedActionCallback) {
        await actionDispatch.execCallback(onAdChangedActionCallback);
      }
    },
    [kanbanId, onAdChangedActionCallback],
    $
  );

  const [onFormSubmit, , $fomDataWithChangeTrigger] = useFormWithValidation<
    Store,
    MarketplaceEditAdDialogState
  >({
    $,
    mandatoryFields,
    checkFieldContentActions: undefined,
    checkFormConsistencyAction: undefined,
    submitValidDataAction: submitValidDataActionCallback,
    t,
  });

  const formWarning = useGetState($.$formWarning);
  const isOnline = useGetState($gs.$session.$isOnline);

  const modalWarning = useMemo(() => {
    // If there is a form warning
    if (formWarning !== undefined) {
      return formWarning;
    }
    // If no form warning exist, check that we're not offline
    if (!isOnline) {
      return t('tabs.marketplace.editAdModalDialog.warnings.offline');
    }
    return undefined;
  }, [formWarning, isOnline, t]);

  const gearboxValues: readonly SimpleOption<GearboxValues>[] = useMemo(
    () => [
      {
        label: `${t('tabs.marketplace.editAdModalDialog.gearboxValues.unknown')}`,
        value: '',
      },
      {
        label: `${t('tabs.marketplace.editAdModalDialog.gearboxValues.automaticGearbox')}`,
        value: 'auto',
      },
      {
        label: `${t('tabs.marketplace.editAdModalDialog.gearboxValues.manualGearbox')}`,
        value: 'manual',
      },
    ],
    [t]
  );
  const fuelValues: readonly SimpleOption<FuelValues>[] = useMemo(
    () => [
      {
        label: `${t('tabs.marketplace.editAdModalDialog.fuelValues.unknown')}`,
        value: '',
      },
      {
        label: `${t('tabs.marketplace.editAdModalDialog.fuelValues.dieselFuel')}`,
        value: 'diesel',
      },
      {
        label: `${t('tabs.marketplace.editAdModalDialog.fuelValues.gasFuel')}`,
        value: 'gas',
      },
      {
        label: `${t('tabs.marketplace.editAdModalDialog.fuelValues.hybridFuel')}`,
        value: 'hybrid',
      },
      {
        label: `${t('tabs.marketplace.editAdModalDialog.fuelValues.electricFuel')}`,
        value: 'electric',
      },
    ],
    [t]
  );

  const marketplaceTypeValues: readonly SimpleOption<MarketplaceProcess>[] = useMemo(
    () => [
      {
        label: `${t('tabs.marketplace.sellProcess.processValues.unknown')}`,
        value: '',
      },
      {
        label: `${t('tabs.marketplace.sellProcess.processValues.sell')}`,
        value: 'SELL',
      },
      {
        label: `${t('tabs.marketplace.sellProcess.processValues.buy')}`,
        value: 'BUY',
      },
    ],
    [t]
  );

  return (
    <ModalCardDialog
      $active={$.$active}
      title={t('tabs.marketplace.editText')}
      onOkClicked={onFormSubmit}
      warning={modalWarning}
      size="max-desktop"
    >
      <>
        <div className="columns">
          <div className="column is-4">
            <ReactSelectFormField
              label={t('tabs.marketplace.editAdModalDialog.brand')}
              $={$fomDataWithChangeTrigger.$brand}
              creation
              suggestions={AVAILABLE_BRANDS}
            />
            <InputFormField
              label={t('tabs.marketplace.editAdModalDialog.model')}
              $={$fomDataWithChangeTrigger.$model}
            />
            <InputFormField
              label={t('tabs.marketplace.editAdModalDialog.motor')}
              $={$fomDataWithChangeTrigger.$motor}
            />
          </div>
          <div className="column is-4">
            <InputFormField
              label={t('tabs.marketplace.editAdModalDialog.mileage')}
              type="number"
              $={$fomDataWithChangeTrigger.$mileage}
            />
            <InputFormField
              label={t('tabs.marketplace.editAdModalDialog.color')}
              $={$fomDataWithChangeTrigger.$color}
            />
            <CalendarFormField
              label={t('tabs.marketplace.editAdModalDialog.dateOfRegistration')}
              $={$fomDataWithChangeTrigger.$dateOfRegistration}
              noExclamationTriangleIfWarning
            />
          </div>
          <div className="column is-4">
            <ReactSelectFormField
              label={t('tabs.marketplace.editAdModalDialog.gearbox')}
              $={$fomDataWithChangeTrigger.$gearbox}
              suggestions={gearboxValues}
            />
            <ReactSelectFormField
              label={t('tabs.marketplace.editAdModalDialog.fuel')}
              $={$fomDataWithChangeTrigger.$fuel}
              suggestions={fuelValues}
            />
            <ReactSelectFormField
              label={t('tabs.marketplace.sellProcess.process')}
              $={$fomDataWithChangeTrigger.$marketplaceProcess}
              suggestions={marketplaceTypeValues}
            />
          </div>
        </div>

        <FormField label={t('tabs.marketplace.editAdModalDialog.markdownAsHtmlPreview')}>
          <MarketplaceAdHeader
            $gs={$gs}
            $publicSellingPrice={$fomDataWithChangeTrigger.$publicSellingPrice}
            $brand={$fomDataWithChangeTrigger.$brand}
            $color={$fomDataWithChangeTrigger.$color}
            $dateOfRegistration={$fomDataWithChangeTrigger.$dateOfRegistration}
            $mileage={$fomDataWithChangeTrigger.$mileage}
            $model={$fomDataWithChangeTrigger.$model}
            $motor={$fomDataWithChangeTrigger.$motor}
          />
          <WithWarning warning={useFormFieldWarning($fomDataWithChangeTrigger.$markdownText)}>
            <Suspense fallback="...">
              <LazyMarkdownEditor $={$fomDataWithChangeTrigger.$markdownText} />
            </Suspense>
          </WithWarning>
        </FormField>
      </>
    </ModalCardDialog>
  );
}
