import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { AttachmentFolder, Kanban, StorageCategories } from '@stimcar/libs-base';
import type { GlobalStoreStateSelector, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { TabElementDef } from '@stimcar/libs-uitoolkit';
import {
  CoreBackendRoutes,
  kanbanHelpers,
  MARKETPLACE_PHOTO_ATTACHMENT_FOLDER,
  MARKETPLACE_TEXT_ATTACHMENT_FOLDER,
  URL_LIST_ELEMENTS_SEPARATOR,
} from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { AttachmentsGallery, DisplayContentOrPlaceholder, Tabs } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { loadAttachmentsGalleryAction } from '../../utils/attachmentGalleryActions.js';
import { useComputeAttachmentUrl } from '../../utils/useComputeAttachmentUrl.js';
import type { MarketplaceDetailsState } from './typings/store.js';
import { MarketplaceAdPreview } from './MarketplaceAdPreview.js';
import { MarketplaceOverview } from './MarketplaceOverview.js';
import { MarketplacePictureStandard } from './MarketplacePictureStandard.js';

const MARKETPLACE_PHOTO_FOLDERS: readonly AttachmentFolder[] = [
  MARKETPLACE_PHOTO_ATTACHMENT_FOLDER,
];
const MARKETPLACE_TEXT_FOLDERS: readonly AttachmentFolder[] = [MARKETPLACE_TEXT_ATTACHMENT_FOLDER];

export interface MarketplaceDetailsProps {
  readonly $: StoreStateSelector<Store, MarketplaceDetailsState>;
  readonly $gs: GlobalStoreStateSelector<Store>;
  readonly isEditable: boolean;
  readonly $selectedKanban: StoreStateSelector<Store, Kanban>;
}

export function MarketplaceDetails({
  $,
  $gs,
  isEditable,
  $selectedKanban,
}: MarketplaceDetailsProps): JSX.Element {
  const [t] = useTranslation('details');
  const isOnline = useGetState($gs.$session.$isOnline);
  const computeAttachmentUrl = useComputeAttachmentUrl($gs);
  const loadAttachmentsActionCallback = useActionCallback(
    async (
      { actionDispatch },
      category: StorageCategories,
      objectId: string,
      folders: readonly string[],
      reloadElements?: boolean
    ) => {
      await actionDispatch.exec(
        loadAttachmentsGalleryAction,
        CoreBackendRoutes.ATTACHMENT_FOLDER(
          category,
          objectId,
          folders.join(URL_LIST_ELEMENTS_SEPARATOR)
        ),
        reloadElements
      );
      actionDispatch.setProperty('loadingStatus', undefined);
    },
    [],
    $.$attachmentGalleryDialogModal
  );

  const kanbanId = useGetState($selectedKanban.$id);

  const tabs = useMemo((): Record<string, string | TabElementDef> => {
    return {
      overview: {
        label: t('tabs.marketplace.overview.title'),
        icon: { id: 'file-invoice-dollar' },
      },
      pictureStandard: {
        label: t('tabs.marketplace.pictureStandard.title'),
        icon: { id: 'camera' },
      },
      preview: {
        label: t('tabs.marketplace.previewTitle'),
        icon: { id: 'globe' },
      },
      photoAttachmentsGallery: {
        label: t('tabs.marketplace.photoAttachmentsGallery'),
        icon: { id: 'image' },
      },
      textAttachmentsGallery: {
        label: t('tabs.marketplace.textAttachmentsGallery'),
        icon: { id: 'brands/markdown' },
      },
    };
  }, [t]);

  const selectedTab = useGetState($.$selectedTab);

  const selectedKanban = useGetState($selectedKanban);
  const isMarketplaceKanban = useMemo(
    () => isTruthy(selectedKanban) && kanbanHelpers.isMarketplaceKanban(selectedKanban),
    [selectedKanban]
  );

  return (
    <DisplayContentOrPlaceholder
      displayCondition={isMarketplaceKanban}
      placeholder={t('kanbanAttributes.emptyPlaceholder')}
      isScrollable
    >
      <div className="container is-fluid mt-3">
        <div className="container is-max-widescreen" style={{ minHeight: 400 }}>
          <Tabs labels={tabs} $selectedTab={$.$selectedTab} isCentered />
          {selectedTab === 'overview' && (
            <MarketplaceOverview
              $={$.$marketplaceOverview}
              $gs={$gs}
              $selectedKanban={$selectedKanban}
            />
          )}
          {selectedTab === 'pictureStandard' && (
            <MarketplacePictureStandard
              $={$}
              $gs={$gs}
              isEditable={isEditable}
              $selectedKanban={$selectedKanban}
            />
          )}
          {selectedTab === 'preview' && (
            <MarketplaceAdPreview
              $={$}
              $gs={$gs}
              isEditable={isEditable}
              $selectedKanban={$selectedKanban}
            />
          )}
          {selectedTab === 'photoAttachmentsGallery' && (
            <AttachmentsGallery
              category="kanban"
              objectId={kanbanId}
              folders={MARKETPLACE_PHOTO_FOLDERS}
              $={$.$attachmentGalleryDialogModal}
              $window={$gs.$window}
              loadAttachmentsActionCallback={loadAttachmentsActionCallback}
              computeAttachmentUrl={computeAttachmentUrl}
              $imageModal={$gs.$imageModal}
              isOnline={isOnline}
            />
          )}
          {selectedTab === 'textAttachmentsGallery' && (
            <AttachmentsGallery
              category="kanban"
              objectId={kanbanId}
              folders={MARKETPLACE_TEXT_FOLDERS}
              $={$.$attachmentGalleryDialogModal}
              $window={$gs.$window}
              loadAttachmentsActionCallback={loadAttachmentsActionCallback}
              computeAttachmentUrl={computeAttachmentUrl}
              $imageModal={$gs.$imageModal}
              isOnline={isOnline}
            />
          )}
        </div>
      </div>
    </DisplayContentOrPlaceholder>
  );
}
