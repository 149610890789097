import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban } from '@stimcar/libs-base';
import type { GlobalStoreStateSelector, StoreStateSelector } from '@stimcar/libs-uikernel';
import {
  CoreBackendRoutes,
  KANBAN_ATTRIBUTES,
  MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID,
  MARKETPLACE_TEXT_ATTACHMENTS_FOLDER_ID,
} from '@stimcar/libs-base';
import { isTruthyAndNotEmpty, KANBAN_MARKETPLACE_STANDARD_PICTURES } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState, useRecordItemSelector } from '@stimcar/libs-uikernel';
import { Button } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { useComputeAttachmentUrl } from '../../utils/useComputeAttachmentUrl.js';
import type { MarketplaceDetailsState } from './typings/store.js';
import { MarketplaceAd } from './MarketplaceAd.js';
import { MarketplaceEditAdModalDialog } from './MarketplaceEditAdModalDialog.js';
import { MARKETPLACE_DETAILS_EMPTY_STATE } from './typings/store.js';

interface MarketplaceAdPicturePreviewProps {
  readonly filename: string;
  readonly kanbanId: string;
  readonly $gs: GlobalStoreStateSelector<Store>;
  readonly $: StoreStateSelector<Store, MarketplaceDetailsState>;
}

function MarketplaceAdPicturePreview({
  $,
  $gs,
  filename,
  kanbanId,
}: MarketplaceAdPicturePreviewProps): JSX.Element {
  const computeAttachmentUrl = useComputeAttachmentUrl($gs);

  const onClickActionCallback = useActionCallback(
    ({ globalActionDispatch }) => {
      globalActionDispatch.applyPayload({
        imageModal: {
          active: true,
          imageIndex: 0,
          imagesUrls: [
            computeAttachmentUrl(
              'kanban',
              MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID,
              filename,
              kanbanId
            ),
          ],
        },
      });
    },
    [computeAttachmentUrl, filename, kanbanId],
    $
  );

  const onKeyDownActionCallback = useActionCallback(
    async ({ actionDispatch }, e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        await actionDispatch.execCallback(onClickActionCallback);
      }
    },
    [onClickActionCallback],
    $
  );

  // We need to add a unique url parameter to avoid the browser to cache the image
  // Otherwise, the image would still appear after deletion because it has been previously loaded
  const src = useMemo(
    () =>
      `${computeAttachmentUrl(
        'kanban',
        MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID,
        filename,
        kanbanId,
        {
          mode: 'cover',
          size: '640x480',
        }
      )}?t=${new Date().getTime()}`,
    [computeAttachmentUrl, filename, kanbanId]
  );

  return (
    <figure className="image is-4by3 is-clickable is-full-height has-background-light">
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <img
        alt=""
        src={src}
        onClick={onClickActionCallback}
        onKeyDown={onKeyDownActionCallback}
        onError={({ currentTarget }) => {
          // eslint-disable-next-line no-param-reassign
          currentTarget.onerror = null; // prevents infinite loop
          // eslint-disable-next-line no-param-reassign
          currentTarget.src = 'transp.png'; // fallback to a placeholder image
        }}
      />
    </figure>
  );
}

interface MarketplaceAdPreviewProps {
  readonly isEditable: boolean;
  readonly $gs: GlobalStoreStateSelector<Store>;
  readonly $selectedKanban: StoreStateSelector<Store, Kanban>;
  readonly $: StoreStateSelector<Store, MarketplaceDetailsState>;
}

export function MarketplaceAdPreview({
  $,
  $gs,
  isEditable,
  $selectedKanban,
}: MarketplaceAdPreviewProps): JSX.Element {
  const [t] = useTranslation('details');
  const isOnline = useGetState($gs.$session.$isOnline);

  const selectedKanban = useGetState($selectedKanban);
  const kanbanId = useGetState($selectedKanban.$id);
  const brand = useGetState($selectedKanban.$infos.$brand);
  const color = useGetState($selectedKanban.$infos.$color);
  const dateOfRegistration = useGetState($selectedKanban.$infos.$dateOfRegistration);
  const mileage = useGetState($selectedKanban.$infos.$mileage);
  const model = useGetState($selectedKanban.$infos.$model);
  const motor = useGetState($selectedKanban.$infos.$motor);

  const $publicSellingPrice = useRecordItemSelector(
    $selectedKanban.$attributes,
    KANBAN_ATTRIBUTES.MKTP_PUBLIC_SELLING_PRICE
  ) as StoreStateSelector<Store, string>;

  const loadAdTextActionCallback = useActionCallback(
    async function loadAdTextAction({ actionDispatch, httpClient }) {
      const textAttachmentFilename =
        selectedKanban?.attributes[KANBAN_ATTRIBUTES.MKTP_TEXT_ATTACHMENT];
      if (!isTruthyAndNotEmpty(textAttachmentFilename as string | undefined)) {
        actionDispatch.setProperty('markdownText', MARKETPLACE_DETAILS_EMPTY_STATE.markdownText);
      } else {
        const textAttachmentResponse = await httpClient.httpGet(
          CoreBackendRoutes.ATTACHMENT(
            'kanban',
            kanbanId,
            MARKETPLACE_TEXT_ATTACHMENTS_FOLDER_ID,
            String(textAttachmentFilename)
          )
        );
        actionDispatch.setProperty('markdownText', await textAttachmentResponse.text());
      }
    },
    [selectedKanban?.attributes, kanbanId],
    $
  );

  const clearAdTextActionCallback = useActionCallback(
    function clearAdTextAction({ actionDispatch }) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { selectedTab, ...rest } = MARKETPLACE_DETAILS_EMPTY_STATE;
      actionDispatch.applyPayload(rest);
    },
    [],
    $
  );

  // Lazily load the ad text
  useEffect((): (() => void) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadAdTextActionCallback();
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    return clearAdTextActionCallback;
  }, [clearAdTextActionCallback, loadAdTextActionCallback]);

  const openEditAdModalDialogActionCallback = useActionCallback(
    async function openEditAdModalDialogAction({ actionDispatch, getState }) {
      // Refresh loaded markdown
      await actionDispatch.execCallback(loadAdTextActionCallback);
      // Init state
      actionDispatch.applyPayload({
        editAdDialog: {
          active: true,
          formData: {
            markdownText: getState().markdownText,
            brand,
            color,
            dateOfRegistration: dateOfRegistration ?? 0,
            mileage: String(mileage),
            model,
            motor,
            publicSellingPrice: String(
              selectedKanban?.attributes[KANBAN_ATTRIBUTES.MKTP_PUBLIC_SELLING_PRICE]
            ),
            gearbox: String(selectedKanban?.attributes[KANBAN_ATTRIBUTES.MKTP_GEARBOX]),
            fuel: String(selectedKanban?.attributes[KANBAN_ATTRIBUTES.MKTP_FUEL]),
            marketplaceProcess: String(
              selectedKanban?.attributes[KANBAN_ATTRIBUTES.MKTP_PROCESS] ?? ''
            ),
          },
        },
      });
    },
    [
      selectedKanban?.attributes,
      brand,
      color,
      dateOfRegistration,
      loadAdTextActionCallback,
      mileage,
      model,
      motor,
    ],
    $
  );

  return (
    <>
      <div className="fixed-grid has-12-cols">
        <div className="grid">
          <div className="cell is-row-span-2 is-col-span-6">
            <MarketplaceAdPicturePreview
              $={$}
              $gs={$gs}
              kanbanId={kanbanId}
              filename={
                KANBAN_MARKETPLACE_STANDARD_PICTURES.external
                  .MARKETPLACE_FRONT_LEFT_THREE_QUARTERS_VIEW_PICTURE.id
              }
            />
          </div>
          <div className="cell is-col-span-3">
            <MarketplaceAdPicturePreview
              $={$}
              $gs={$gs}
              kanbanId={kanbanId}
              filename={
                KANBAN_MARKETPLACE_STANDARD_PICTURES.external
                  .MARKETPLACE_REAR_RIGHT_THREE_QUARTERS_VIEW_PICTURE.id
              }
            />
          </div>
          <div className="cell is-col-span-3">
            <MarketplaceAdPicturePreview
              $={$}
              $gs={$gs}
              kanbanId={kanbanId}
              filename={
                KANBAN_MARKETPLACE_STANDARD_PICTURES.internal
                  .MARKETPLACE_FRONT_RIGHT_DOOR_INTERNAL_PICTURE.id
              }
            />
          </div>
          <div className="cell is-col-span-3">
            <MarketplaceAdPicturePreview
              $={$}
              $gs={$gs}
              kanbanId={kanbanId}
              filename={
                KANBAN_MARKETPLACE_STANDARD_PICTURES.internal.MARKETPLACE_COMPLETE_DASHBOARD_PICTURE
                  .id
              }
            />
          </div>
          <div className="cell is-col-span-3">
            <MarketplaceAdPicturePreview
              $={$}
              $gs={$gs}
              kanbanId={kanbanId}
              filename={
                KANBAN_MARKETPLACE_STANDARD_PICTURES.external.MARKETPLACE_LEFT_SIDE_PICTURE.id
              }
            />
          </div>
        </div>
      </div>
      <div>
        {isEditable && (
          <Button
            iconId="edit"
            disabled={!isOnline}
            additionalClass="is-primary"
            additionalStyle={{ float: 'right' }}
            label={t('tabs.marketplace.editText')}
            onClick={openEditAdModalDialogActionCallback}
          />
        )}
        <MarketplaceAd
          $gs={$gs}
          $markdownText={$.$markdownText}
          $brand={$selectedKanban.$infos.$brand}
          $color={$selectedKanban.$infos.$color}
          $model={$selectedKanban.$infos.$model}
          $motor={$selectedKanban.$infos.$motor}
          $publicSellingPrice={$publicSellingPrice}
          $mileage={$selectedKanban.$infos.$mileage}
          $dateOfRegistration={$selectedKanban.$infos.$dateOfRegistration}
        />
        <MarketplaceEditAdModalDialog
          $gs={$gs}
          $={$.$editAdDialog}
          kanbanId={kanbanId}
          onAdChangedActionCallback={loadAdTextActionCallback}
        />
      </div>
    </>
  );
}
