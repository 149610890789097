import React from 'react';
import { useTranslation } from 'react-i18next';
import type { PackageDeal } from '@stimcar/libs-base';
import type { DeepPartial, Defect, RefurbishingOption } from '@stimcar/libs-kernel';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import { packageDealHelpers } from '@stimcar/libs-base';
import { isTruthy, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { FaIcon } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../state/typings/store.js';
import type { AddOrUpdateDefectDialogState } from '../../../typings/store.js';
import { openDeleteRefurbishingOptionModalAction } from './DeleteRefurbishingOptionModal.js';
import { openUpdateRefurbishingOptionLabelModalAction } from './UpdateRefurbishingOptionLabelModal.js';

function canMoveUp(refurbishingOption: RefurbishingOption): boolean {
  return refurbishingOption.index > 1;
}

function canMoveDown(
  refurbishingOption: RefurbishingOption,
  refurbishingOptions: readonly RefurbishingOption[]
): boolean {
  return refurbishingOption.index < refurbishingOptions.length;
}

function moveRefurbishingOptionAction(
  { actionDispatch, getState }: ActionContext<Store, readonly RefurbishingOption[]>,
  refurbishingOption: RefurbishingOption,
  delta: 1 | -1
): void {
  const refurbishingOptions = getState();

  if (delta === 1 && !canMoveDown(refurbishingOption, refurbishingOptions)) {
    return;
  }
  if (delta === -1 && !canMoveUp(refurbishingOption)) {
    return;
  }

  const startingIndex = refurbishingOption.index;
  const endingIndex = startingIndex + delta;
  const otherRefurbishingOption = refurbishingOptions.find(({ index }) => index === endingIndex);

  if (isTruthy(otherRefurbishingOption)) {
    const payload: readonly DeepPartial<Defect>[] = [
      {
        id: refurbishingOption.id,
        index: endingIndex,
      },
      {
        id: otherRefurbishingOption.id,
        index: startingIndex,
      },
    ];
    actionDispatch.applyPayload(payload);
  }
}

interface RefurbishingOptionItemProps {
  readonly $: StoreStateSelector<Store, AddOrUpdateDefectDialogState>;
  readonly refurbishingOption: RefurbishingOption;
  readonly packageDeal: PackageDeal;
}

export function RefurbishingOptionItem({
  $,
  refurbishingOption,
  packageDeal,
}: RefurbishingOptionItemProps): JSX.Element {
  const [t] = useTranslation('operators', { keyPrefix: 'defects.addOrUpdateDefect' });

  const { id, label, index } = refurbishingOption;

  const { carElement } = packageDeal;

  const packageDealLabel = packageDealHelpers.getPackageDealDisplayedLabel(packageDeal);

  const moveUpRefurbishingOptionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(moveRefurbishingOptionAction, refurbishingOption, -1);
    },
    [refurbishingOption],
    $.$refurbishingOptions
  );

  const moveDownRefurbishingOptionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(moveRefurbishingOptionAction, refurbishingOption, 1);
    },
    [refurbishingOption],
    $.$refurbishingOptions
  );

  const refurbishingOptions = useGetState($.$refurbishingOptions);

  const openUpdateRefurbishingOptionLabelModalActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openUpdateRefurbishingOptionLabelModalAction, id, label);
    },
    [id, label],
    $.$updateRefurbishingOptionLabelDialogState
  );

  const openDeleteRefurbishingOptionDialogCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openDeleteRefurbishingOptionModalAction, id);
    },
    [id],
    $.$deleteRefurbishingOptionDialogState
  );

  return (
    <tr>
      <td>{index}</td>
      <td>{label}</td>
      <td>{nonnull(carElement).label}</td>
      <td>{packageDealLabel}</td>
      <td className="has-text-right">
        {packageDealHelpers.getPackageDealsPriceWithVAT([packageDeal]).toFixed(2)}
      </td>
      <td
        aria-label="actions"
        style={{ whiteSpace: 'nowrap' }}
        className="has-text-centered is-flex-wrap-nowrap px-0"
      >
        <button
          key="update"
          aria-label="update"
          type="button"
          className="button is-small is-transparent"
          title={t('actions.update')}
          onClick={openUpdateRefurbishingOptionLabelModalActionCallback}
        >
          <FaIcon id="edit" tooltip={t('actions.update')} />
        </button>
        <button
          key="delete"
          aria-label="delete"
          type="button"
          className="button is-small is-transparent"
          title={t('actions.delete')}
          onClick={openDeleteRefurbishingOptionDialogCallback}
        >
          <FaIcon id="trash" tooltip={t('actions.delete')} />
        </button>
        <button
          key="up"
          aria-label="up"
          type="button"
          className="button is-small is-transparent"
          title={t('actions.up')}
          onClick={moveUpRefurbishingOptionCallback}
          disabled={!canMoveUp(refurbishingOption)}
        >
          <FaIcon id="square-caret-up" tooltip={t('actions.up')} />
        </button>
        <button
          key="down"
          aria-label="down"
          type="button"
          className="button is-small is-transparent"
          title={t('actions.down')}
          onClick={moveDownRefurbishingOptionCallback}
          disabled={!canMoveDown(refurbishingOption, refurbishingOptions)}
        >
          <FaIcon id="square-caret-down" tooltip={t('actions.down')} />
        </button>
      </td>
    </tr>
  );
}
