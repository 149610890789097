import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Attachment, AttachmentMetadata, Kanban } from '@stimcar/libs-base';
import type {
  MarketplaceStandardExternalPicture,
  MarketplaceStandardInternalPicture,
} from '@stimcar/libs-kernel';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps, ConfirmAttachmentDialogState } from '@stimcar/libs-uitoolkit';
import {
  CoreBackendRoutes,
  MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID,
  sortingHelpers,
} from '@stimcar/libs-base';
import { KANBAN_MARKETPLACE_STANDARD_PICTURES, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useArrayItemSelector, useGetState } from '@stimcar/libs-uikernel';
import {
  ConfirmAttachmentRemovalDialog,
  EMPTY_CONFIRM_ATTACHMENT_DIALOG_STATE,
} from '@stimcar/libs-uitoolkit';
import type { PictureInputState } from '../../../lib/components/typings/store.js';
import type { Store } from '../../state/typings/store.js';
import { PictureInput } from '../../../lib/components/pictureInput/PictureInput.js';
import { appendRegisteredBrowserSessionToken } from '../../utils/security.js';
import { useComputeAttachmentUrl } from '../../utils/useComputeAttachmentUrl.js';
import { shouldAllowAttachmentDeletion } from '../kanbanDetailsUtils.js';
import type { MarketplaceDetailsState } from './typings/store.js';

const EXTERNAL_PICTURES_ENTRIES = Object.entries(
  KANBAN_MARKETPLACE_STANDARD_PICTURES.external
) as readonly [
  keyof (typeof KANBAN_MARKETPLACE_STANDARD_PICTURES)['external'],
  { readonly id: MarketplaceStandardExternalPicture; index: number },
][];

const INTERNAL_PICTURES_ENTRIES = Object.entries(
  KANBAN_MARKETPLACE_STANDARD_PICTURES.internal
) as readonly [
  keyof (typeof KANBAN_MARKETPLACE_STANDARD_PICTURES)['internal'],
  { readonly id: MarketplaceStandardInternalPicture; index: number },
][];

interface MarketplaceStandardPictureCellProps extends AppProps<Store> {
  readonly kanbanId: string;
  readonly pictureId: string;
  readonly isEditable: boolean;
  readonly translationKey: string;
  readonly $: StoreStateSelector<Store, readonly PictureInputState[]>;
  readonly $confirmAttachmentRemovalDialog: StoreStateSelector<Store, ConfirmAttachmentDialogState>;
}

function MarketplaceStandardPictureCell({
  $,
  $gs,
  kanbanId,
  pictureId,
  isEditable,
  translationKey,
  $confirmAttachmentRemovalDialog,
}: MarketplaceStandardPictureCellProps): JSX.Element {
  const [t] = useTranslation('details');
  const $pictureStateSelector = useArrayItemSelector($, pictureId);
  const placeholderPicturePath = useMemo(
    () => `img/marketplace/picture-standard/${pictureId}`,
    [pictureId]
  );
  return (
    <div className="cell is-col-span-3">
      <PictureInput
        $gs={$gs}
        kanbanId={kanbanId}
        filename={pictureId}
        isEditable={isEditable}
        $={$pictureStateSelector}
        placeholderPicturePath={placeholderPicturePath}
        folder={MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID}
        $confirmAttachmentRemovalDialog={$confirmAttachmentRemovalDialog}
        label={t(`tabs.marketplace.pictureStandard.pictures.${translationKey}`)}
        notSetPictureAdditionalLayer="img/marketplace/picture-standard/set-picture-layer.webp"
      />
    </div>
  );
}

interface MarketplacePictureStandardProps extends AppProps<Store> {
  readonly isEditable: boolean;
  readonly $selectedKanban: StoreStateSelector<Store, Kanban>;
  readonly $: StoreStateSelector<Store, MarketplaceDetailsState>;
}

export function MarketplacePictureStandard({
  $,
  $gs,
  isEditable,
  $selectedKanban,
}: MarketplacePictureStandardProps): JSX.Element {
  const [t] = useTranslation('details');
  const selectedKanban = useGetState($selectedKanban);
  const computeAttachmentUrl = useComputeAttachmentUrl($gs);

  const sortedExternalPicturesEntries = useMemo(
    () =>
      [...EXTERNAL_PICTURES_ENTRIES].sort(([, { index: firstIndex }], [, { index: secondIndex }]) =>
        sortingHelpers.compareNumbers(firstIndex, secondIndex, 'DOWN')
      ),
    []
  );

  const sortedInternalPicturesEntries = useMemo(
    () =>
      [...INTERNAL_PICTURES_ENTRIES].sort(([, { index: firstIndex }], [, { index: secondIndex }]) =>
        sortingHelpers.compareNumbers(firstIndex, secondIndex, 'DOWN')
      ),
    []
  );

  const shouldRestrictAttachmentRemoval = useCallback(
    (attachment: Attachment, metadata: AttachmentMetadata | undefined): boolean => {
      return !shouldAllowAttachmentDeletion(selectedKanban?.status, attachment, metadata);
    },
    [selectedKanban]
  );

  const onRemoveActionCallback = useActionCallback(
    async ({ httpClient, getState, actionDispatch, getGlobalState }) => {
      const filename = getState().confirmAttachmentRemovalDialog.name;
      await httpClient.httpGet(
        appendRegisteredBrowserSessionToken(
          CoreBackendRoutes.ATTACHMENT(
            'kanban',
            selectedKanban.id,
            MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID,
            filename
          ),
          nonnull(getGlobalState().session.infos).sessionToken
        ),
        'DELETE'
      );
      actionDispatch
        .scopeProperty('standardPictures')
        .scopeArrayItem(filename)
        .setProperty('isSet', false);
      actionDispatch
        .scopeProperty('confirmAttachmentRemovalDialog')
        .setValue(EMPTY_CONFIRM_ATTACHMENT_DIALOG_STATE);
    },
    [selectedKanban.id],
    $.$marketplacePictureStandard
  );

  return (
    <div>
      <h4 className="title is-4 mt-0 mb-3">{t('tabs.marketplace.pictureStandard.external')}</h4>
      <div className="fixed-grid has-12-cols">
        <div className="grid">
          {sortedExternalPicturesEntries.map(([key, { id }]) => (
            <MarketplaceStandardPictureCell
              key={key}
              $gs={$gs}
              pictureId={id}
              translationKey={key}
              isEditable={isEditable}
              kanbanId={selectedKanban.id}
              $={$.$marketplacePictureStandard.$standardPictures}
              $confirmAttachmentRemovalDialog={
                $.$marketplacePictureStandard.$confirmAttachmentRemovalDialog
              }
            />
          ))}
        </div>
      </div>
      <h4 className="title is-4 pt-3 mb-3">{t('tabs.marketplace.pictureStandard.internal')}</h4>
      <div className="fixed-grid has-12-cols">
        <div className="grid">
          {sortedInternalPicturesEntries.map(([key, { id }]) => (
            <MarketplaceStandardPictureCell
              key={key}
              $gs={$gs}
              pictureId={id}
              translationKey={key}
              isEditable={isEditable}
              kanbanId={selectedKanban.id}
              $={$.$marketplacePictureStandard.$standardPictures}
              $confirmAttachmentRemovalDialog={
                $.$marketplacePictureStandard.$confirmAttachmentRemovalDialog
              }
            />
          ))}
        </div>
      </div>
      <ConfirmAttachmentRemovalDialog
        category="kanban"
        objectId={selectedKanban.id}
        onOkClicked={onRemoveActionCallback}
        $={$.$marketplacePictureStandard.$confirmAttachmentRemovalDialog}
        computeAttachmentUrl={computeAttachmentUrl}
        okLabel={t('confirmAttachmentRemovalDialog.okLabel')}
        shouldRestrictRemove={shouldRestrictAttachmentRemoval}
      >
        <p>{t('confirmAttachmentRemovalDialog.message')}</p>
      </ConfirmAttachmentRemovalDialog>
    </div>
  );
}
