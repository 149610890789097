export type GearboxValues = '' | 'auto' | 'manual';
export type FuelValues = '' | 'diesel' | 'gas' | 'hybrid' | 'electric';

export const MKTP_SELL_PROCESS = 'SELL';
export const MKTP_BUY_PROCESS = 'BUY';

export type MarketplaceProcess = '' | typeof MKTP_BUY_PROCESS | typeof MKTP_SELL_PROCESS;

export const MKTP_OFFLINE_STATUS = 'offline';
export const MKTP_ONLINE_STATUS = 'online';
export const MKTP_SOLD_STATUS = 'sold';

export type MarketplaceStatus =
  | typeof MKTP_OFFLINE_STATUS
  | typeof MKTP_ONLINE_STATUS
  | typeof MKTP_SOLD_STATUS;

export const MINIMAL_BUY_VARIABLE_FEES = 490;
export const BUY_COMMISSION_RATE = 0.059;

export type MarketplaceSaleFees = {
  readonly tradeInPriceWithVAT: number;
  readonly fixedFeesWithVAT: number;
  readonly refurbishFeesWithVAT: number;
  // fixedFeesWithVAT + refurbishFeesWithVAT
  readonly subTotalStimcarWithVAT: number;
  // tradeInPriceWithVAT + subTotalStimcarWithVAT
  readonly totalWithVAT: number;
};

export type MarketplaceBuyFees = {
  readonly tradeInPriceWithVAT: number;
  readonly fixedFeesWithVAT: number;
  readonly variableFeesWithVAT: number;
  readonly refurbishFeesWithVAT: number;
  readonly registrationFeesWithVAT: number;
  // fixedFeesWithVAT+ variableFeesWithVAT + refurbishFeesWithVAT + registrationFeesWithVAT
  readonly subTotalStimcarWithVAT: number;
  // tradeInPriceWithVAT + subTotalStimcarWithVAT
  readonly totalWithVAT: number;
};

export type MarketplacePaymentDetails = {
  readonly deposit: number;
  readonly total: number;
};

export type MarketplaceOverviewRequest = {
  readonly saleFees?: MarketplaceSaleFees;
  readonly buyFees?: MarketplaceBuyFees;
  readonly paymentDetails?: MarketplacePaymentDetails;
  readonly buyer?: string;
};
