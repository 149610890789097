import React from 'react';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps, ConfirmAttachmentDialogState } from '@stimcar/libs-uitoolkit';
import { MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID } from '@stimcar/libs-base';
import { useGetState } from '@stimcar/libs-uikernel';
import type { PictureInputState } from '../../../../../lib/components/typings/store.js';
import type { Store } from '../../../../state/typings/store.js';
import { PictureInput } from '../../../../../lib/components/pictureInput/PictureInput.js';

interface DefectPictureSelectorProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, PictureInputState>;
  readonly $kanbanId: StoreStateSelector<Store, string>;
  readonly placeholderPicturePath: string;
  readonly $confirmAttachmentRemovalDialog: StoreStateSelector<Store, ConfirmAttachmentDialogState>;
  readonly isEditable?: boolean;
}

export function DefectPictureSelector({
  $,
  $kanbanId,
  placeholderPicturePath,
  $confirmAttachmentRemovalDialog,
  isEditable = true,
  $gs,
}: DefectPictureSelectorProps): JSX.Element {
  const kanbanId = useGetState($kanbanId);
  const filename = useGetState($.$id);
  return (
    <div className="cell is-col-span-3">
      <PictureInput
        $gs={$gs}
        folder={MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID}
        kanbanId={kanbanId}
        filename={filename}
        isEditable={isEditable}
        placeholderPicturePath={placeholderPicturePath}
        $={$}
        $confirmAttachmentRemovalDialog={$confirmAttachmentRemovalDialog}
        notSetPictureAdditionalLayer="img/marketplace/picture-standard/set-picture-layer.webp"
      />
    </div>
  );
}
