import React, { useMemo } from 'react';
import type { Defect, PositionOnCar } from '@stimcar/libs-kernel';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID, nonDeleted } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { MarkerOnPictureComponent, PictureSelector, useGetState } from '@stimcar/libs-uikernel';
import type { Store } from '../../../state/typings/store.js';
import { useComputeAttachmentUrl } from '../../../utils/useComputeAttachmentUrl.js';
import { getExternalStandardPicIds, getPictureIconPath } from './defectUtils.js';

type PositionOnCarWithIndex = {
  readonly index: number;
  readonly positionOnCar: PositionOnCar;
};

interface DefectsOnCarDisplayProps extends AppProps<Store> {
  readonly $selectedPictureId: StoreStateSelector<Store, string>;
  readonly $kanbanId: StoreStateSelector<Store, string>;
  readonly $defects: StoreStateSelector<Store, readonly Defect[]>;
}

export function DefectsOnCarDisplay({
  $selectedPictureId,
  $kanbanId,
  $defects,
  $gs,
}: DefectsOnCarDisplayProps): JSX.Element {
  const kanbanId = useGetState($kanbanId);

  const defectsPositions: readonly PositionOnCarWithIndex[] = useGetState($defects)
    .filter(nonDeleted)
    .map(({ positionOnCar, index }) => {
      if (isTruthy(positionOnCar)) {
        return { positionOnCar, index };
      }
      return null;
    })
    .filter(isTruthy);

  const atLeastOneDefectPresentOnPictureCallback = (selectedPictureId: string) => {
    const hasDefect = defectsPositions.some(
      ({ positionOnCar }) =>
        isTruthy(positionOnCar.pictureId) && selectedPictureId === positionOnCar.pictureId
    );
    return hasDefect ? 'button-has-defect' : undefined;
  };

  const picturesIds = useMemo(() => getExternalStandardPicIds(), []);

  const computeAttachmentUrl = useComputeAttachmentUrl($gs);

  const getPictureSrcFromPictureId = (pictureId: string): string => {
    return computeAttachmentUrl(
      'kanban',
      MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID,
      pictureId,
      kanbanId,
      {
        mode: 'cover',
        size: '480x360',
      }
    );
  };

  return (
    <PictureSelector
      $selectedPictureId={$selectedPictureId}
      picturesIds={picturesIds}
      getIconSrcFromPictureId={getPictureIconPath}
      getPictureSrcFromPictureId={getPictureSrcFromPictureId}
      getAdditionalLayerOnButtonClassnameCallback={atLeastOneDefectPresentOnPictureCallback}
    >
      <>
        {defectsPositions.map(({ positionOnCar, index }) => (
          <MarkerOnPictureComponent
            key={index}
            positionOnPicture={positionOnCar}
            $selectedPictureId={$selectedPictureId}
          >
            <div className="bullet-marker defect-marker has-text-centered is-size-7 has-text-white">
              {index}
            </div>
          </MarkerOnPictureComponent>
        ))}
      </>
    </PictureSelector>
  );
}
